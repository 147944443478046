import { useTheme } from '@react-navigation/native';
import { createNativeStackNavigator, NativeStackNavigationOptions } from '@react-navigation/native-stack';
import i18n from 'i18n-js';
import { Platform } from 'react-native';

import TasksDetailScreen from "../screens/TasksDetailScreen";
import TasksModuleListScreen from "../screens/TasksModuleListScreen";
import TasksOverviewScreen from "../screens/TasksOverviewScreen";
import { TasksStackParamList } from '../types';


/*
    The navigator of the Tasks tab.
*/
export default function TasksStackScreen() {
    const { colors } = useTheme();

    const TasksStack = createNativeStackNavigator<TasksStackParamList>();

    const parentHeaderOptions: NativeStackNavigationOptions = {
        title: i18n.t('TASKSOVERVIEWSCREEN_TITLE'),
        headerLargeTitle: true, // iOS only
        headerShadowVisible: false, // hide divider line
    }
    const childHeaderOptions: NativeStackNavigationOptions = {};

    if (Platform.OS === 'web') {
        Object.assign(parentHeaderOptions, {
            headerStyle: { backgroundColor: colors.background, height: 96 },
            headerTitleStyle: { fontSize: 26, fontWeight: '700' },
        });
        Object.assign(childHeaderOptions, { headerTitleAlign: 'center' });
    }

    return <TasksStack.Navigator>
        <TasksStack.Screen name="TasksOverview" component={TasksOverviewScreen}
            options={parentHeaderOptions}
        />
        <TasksStack.Screen name="TasksModuleList" component={TasksModuleListScreen}
            options={({ route }) => ({
                title: i18n.t(`TASKTYPE_${route.params.taskType.toUpperCase()}`),
                ...childHeaderOptions
            })}
        />
        <TasksStack.Screen name="TasksDetail" component={TasksDetailScreen}
            options={({ route }) => ({
                title: i18n.t(`TASKTYPE_${route.params.taskType.toUpperCase()}`),
                ...childHeaderOptions
            })}
        />
    </TasksStack.Navigator>;
}
