import { useTheme } from '@react-navigation/native';
import { createNativeStackNavigator, NativeStackNavigationOptions } from '@react-navigation/native-stack';
import i18n from 'i18n-js';
import { Platform } from 'react-native';

import ModulesDetailScreen from "../screens/ModulesDetailScreen";
import ModulesListScreen from "../screens/ModulesListScreen";
import { ModulesStackParamList, } from '../types';


export default function ModulesStackScreen() {
    const { colors } = useTheme();

    const ModulesStack = createNativeStackNavigator<ModulesStackParamList>();

    const parentHeaderOptions: NativeStackNavigationOptions = {
        title: i18n.t('MODULEINDEXSCREEN_TITLE'),
        headerLargeTitle: true, // iOS only
        headerShadowVisible: false, // hide divider line
    }
    const childHeaderOptions: NativeStackNavigationOptions = {};

    if (Platform.OS === 'web') {
        Object.assign(parentHeaderOptions, {
            headerStyle: { backgroundColor: colors.background, height: 96 },
            headerTitleStyle: { fontSize: 26, fontWeight: '700' },
        });
        Object.assign(childHeaderOptions, { headerTitleAlign: 'center' });
    }

    return <ModulesStack.Navigator
    // https://github.com/react-navigation/react-navigation/issues/10465
    // This doesn't work:
    // screenOptions={{ headerTintColor: colors.text }}
    >
        <ModulesStack.Screen name="ModulesList" component={ModulesListScreen}
            options={parentHeaderOptions}
        />
        <ModulesStack.Screen name="ModulesDetail" component={ModulesDetailScreen}
            // https://reactnavigation.org/docs/headers/#using-params-in-the-title
            options={({ route }) => (childHeaderOptions)}
        />
    </ModulesStack.Navigator>;
}
