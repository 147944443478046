import { Ionicons } from '@expo/vector-icons';
import * as Analytics from 'expo-firebase-analytics';
import i18n from 'i18n-js';
import { Image, ImageBackground, Linking, Pressable, StyleSheet, Text, View } from 'react-native';

import images from '../assets/images/index';
import { MyPressable } from '../components/MyComponents';
import { themedStyles } from '../components/Themed';
import Colors from '../constants/Colors';
import { styles as sharedLayoutStyles } from "../constants/Layout";
import { useAuth } from '../contexts/AuthContext';
import useColorScheme from '../hooks/useColorScheme';


// https://reactnavigation.org/docs/auth-flow/#fill-in-other-components
export default function SignInScreen() {
    const { signIn } = useAuth();
    const colorScheme = useColorScheme();

    const contactUsURL = i18n.t('SIGNINSCREEN_CONTACT_GRONSKA_URL');

    function onPress() {
        signIn();

        Analytics.logEvent('sign_in', {
            screen: 'SignInScreen'
        });
    }

    return (
        <View style={[sharedLayoutStyles.rootContainer, sharedLayoutStyles.rootContentContainer]}>
            <ImageBackground source={images.splash} style={styles.container} resizeMode='cover'>
                <ImageBackground source={images.signin_module} style={[styles.moduleRenderedImageBackground, { width: 327, height: 398 }]}>
                    <Image source={images.growoff_logo} style={{ width: 136, height: 43 }} />
                </ImageBackground>
                <View style={styles.actionButtonRow}>
                    <MyPressable
                        style={({ pressed }) => [
                            themedStyles.actionPressable,
                            pressed && { backgroundColor: Colors[colorScheme].actionHighlighted }
                        ]}
                        onPress={onPress}>

                        {({ pressed }) =>
                            <View style={styles.actionPressableRow}>
                                <Ionicons name="enter-outline" size={24} style={{ color: pressed ? Colors[colorScheme].actionTitleHighlighted : Colors[colorScheme].actionTitle }} />
                                <Text style={[
                                    styles.actionButtonTitle,
                                    { color: pressed ? Colors[colorScheme].actionTitleHighlighted : Colors[colorScheme].actionTitle }
                                ]}>{i18n.t('SIGNINSCREEN_SIGN_IN_BUTTON_TITLE')}</Text>
                            </View>
                        }
                    </MyPressable>
                </View>
                <View style={styles.footerTextRow}>
                    <Text style={{ color: Colors[colorScheme].actionTitle }}>{i18n.t('SIGNINSCREEN_NOTCUSTOMER_TEXT')}</Text>
                </View>
                {/* https://stackoverflow.com/a/71591170/594211 */}
                <Pressable style={styles.footerTextRow} onPress={() => Linking.openURL(contactUsURL)}>
                    {({ pressed }) =>
                        <>
                            <Text style={[
                                { textDecorationLine: 'underline' },
                                { color: pressed ? Colors[colorScheme].actionTitleHighlighted : Colors[colorScheme].actionTitle }
                            ]}>{i18n.t('SIGNINSCREEN_CONTACT_GRONSKA_LINK')}</Text>
                            <Ionicons name="md-open-outline" size={16} style={[
                                styles.footerOpenExternalIcon,
                                { color: pressed ? Colors[colorScheme].actionTitleHighlighted : Colors[colorScheme].actionTitle }
                            ]} />
                        </>
                    }
                </Pressable>
            </ImageBackground>
        </View >
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        width: '100%',
        maxWidth: 428,
        alignItems: 'center',    // horizontal
        justifyContent: 'center', // vertical

        // borderWidth: 1,
        // borderColor: 'red'
    },
    moduleRenderedImageBackground: {
        alignItems: 'center',    // horizontal
        justifyContent: 'flex-end' // vertical
    },
    actionButtonRow: {
        paddingVertical: 16
    },
    actionPressableRow: {
        flexDirection: 'row',
        alignItems: 'center',    // vertical
    },
    actionButtonTitle: {
        fontSize: 16,
        fontWeight: '500',
        marginLeft: 4
    },
    footerTextRow: {
        flexDirection: 'row',
        marginBottom: 2
    },
    footerOpenExternalIcon: {
        marginLeft: 4
    },
});
