import { Ionicons } from '@expo/vector-icons';
import { StyleSheet, Text, View } from 'react-native';

import Colors from '../constants/Colors';
import useColorScheme from '../hooks/useColorScheme';


export default function (props: { title: string, body: string }) {
    const colorScheme = useColorScheme();

    return <View style={[styles.calloutView, { backgroundColor: Colors[colorScheme].calloutBackground }]}>
        <View style={styles.calloutIconColumn}>
            <Ionicons name="warning" size={24} color="#ED694E" />
        </View>
        <View style={styles.calloutTextColumn}>
            <Text style={styles.calloutHeaderText}>{props.title}</Text>
            <Text style={styles.calloutText}>{props.body}</Text>
        </View>
    </View>;
}


const styles = StyleSheet.create({
    calloutView: {
        padding: 16,
        marginBottom: 16,
        flexDirection: 'row',
    },
    calloutIconColumn: {
        paddingRight: 12,
    },
    calloutTextColumn: {
        flex: 1,
        // borderWidth: 1
    },
    calloutHeaderText: {
        fontSize: 16,
        fontWeight: '500',
        paddingTop: 4,
        paddingBottom: 8,
    },
    calloutText: {
        fontSize: 14,
    },
});
