/**
 * If you are not familiar with React Navigation, refer to the "Fundamentals" guide:
 * https://reactnavigation.org/docs/getting-started
 *
 */
// import { FontAwesome } from '@expo/vector-icons';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { NavigationContainer, useNavigationContainerRef, DefaultTheme, DarkTheme } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import * as Analytics from 'expo-firebase-analytics';
import i18n from 'i18n-js';
import * as React from 'react';
import { ColorSchemeName, Image, Platform } from 'react-native';

import images from '../assets/images';
import Colors from '../constants/Colors';
import { useAuth } from '../contexts/AuthContext';
import { DataProvider, useData } from '../contexts/DataContext';
import useColorScheme from '../hooks/useColorScheme';
import AccountScreen from '../screens/AccountScreen';
import ModulesStackScreen from '../screens/ModulesStackScreen';
import NotFoundScreen from '../screens/NotFoundScreen';
import SignInScreen from '../screens/SignInScreen';
import SupportStackScreen from '../screens/SupportStackScreen';
import TasksStackScreen from '../screens/TasksStackScreen';
import { RootStackParamList, RootTabParamList } from '../types';
import LinkingConfiguration from './LinkingConfiguration';


export default function Navigation({ colorScheme }: { colorScheme: NonNullable<ColorSchemeName> }) {
  // https://reactnavigation.org/docs/themes/#basic-usage
  const MyTheme = {
    ...(colorScheme === 'dark' ? DarkTheme : DefaultTheme),
    colors: {
      ...(colorScheme === 'dark' ? DarkTheme : DefaultTheme).colors,
      ...(Colors[colorScheme]), // override defaults
    },
  };

  // https://reactnavigation.org/docs/screen-tracking
  const navigationRef = useNavigationContainerRef();
  const routeNameRef = React.useRef<string>();

  return (
    <NavigationContainer
      // https://docs.expo.dev/versions/latest/sdk/firebase-analytics/#react-navigation
      // https://reactnavigation.org/docs/screen-tracking 
      ref={navigationRef}
      onReady={() => {
        routeNameRef.current = navigationRef.getCurrentRoute()!.name;
      }}
      onStateChange={async () => {
        if (Platform.OS === 'web') {  // TODO for iOS and Android
          const previousRouteName = routeNameRef.current;
          const currentRouteName = navigationRef.getCurrentRoute()!.name;

          if (previousRouteName !== currentRouteName) {
            // The line below uses the expo-firebase-analytics tracker
            // https://docs.expo.io/versions/latest/sdk/firebase-analytics/
            // Change this line to use another Mobile analytics SDK
            await Analytics.logEvent('screen_view', { screen_name: currentRouteName });

            await Analytics.setUserProperties({ 'color_scheme': colorScheme });
          }

          // Save the current route name for later comparison
          routeNameRef.current = currentRouteName;
        }
      }}

      // Workaround for Web: Selecting first tab unexpectedly navigates back
      // when linking and initialRouteName are used
      // https://github.com/react-navigation/react-navigation/issues/10481
      linking={Platform.OS === "web" ? undefined : LinkingConfiguration}
      theme={MyTheme}>
      <RootNavigator />
    </NavigationContainer>
  );
}

/**
 * A root stack navigator is often used for displaying modals on top of all other content.
 * https://reactnavigation.org/docs/modal
 */
const Stack = createNativeStackNavigator<RootStackParamList>();

function RootNavigator() {
  const { authState } = useAuth();
  // console.debug(authState);

  // AUTH: From https://reactnavigation.org/docs/auth-flow/#define-our-screens
  // and https://reactnavigation.org/examples/6.x/auth-flow.js
  return (
    <DataProvider>
      <Stack.Navigator>
        {!authState.userToken ? (
          // No token found, user isn't signed in
          <Stack.Screen
            name="SignIn"
            component={SignInScreen}
            options={{
              title: 'GrowOff',
              headerShown: false,
              // When logging out, a pop animation feels intuitive
              // You can remove this if you want the default 'push' animation
              // animationTypeForReplace: authState.isSignout ? 'pop' : 'push',
            }}
          />
        ) : (
          // User is signed in
          <>
            <Stack.Screen name="Root" component={BottomTabNavigator} options={{ headerShown: false }} />
            <Stack.Screen name="NotFound" component={NotFoundScreen} options={{ title: 'Oops!' }} />
            {/* <Stack.Group screenOptions={{ presentation: 'modal' }}> */}
            {/* </Stack.Group> */}
          </>
        )}
      </Stack.Navigator>
    </DataProvider>
  );
}

/**
 * A bottom tab navigator displays tab buttons on the bottom of the display to switch screens.
 * https://reactnavigation.org/docs/bottom-tab-navigator
 */
const BottomTab = createBottomTabNavigator<RootTabParamList>();

function BottomTabNavigator() {
  const colorScheme = useColorScheme();
  const { tasks, checkedTasks } = useData();

  const taskCount = tasks?.filter(t => t.module.online && !checkedTasks.includes(t)).length;

  return (
    <BottomTab.Navigator
      initialRouteName="TasksTab"
      screenOptions={{
        headerShown: false,
        tabBarStyle: { borderTopWidth: 0 }, // hide divider line
      }}>
      <BottomTab.Screen
        name="ModulesTab"
        component={ModulesStackScreen}
        // https://reactnavigation.org/docs/native-stack-navigator#options
        options={{
          title: i18n.t('BOTTOMTAB_MODULES_TITLE'),
          tabBarIcon: ({ focused }) => <Image
            source={focused ? (colorScheme === 'dark' ? images.tabbar_modules_selected_dark : images.tabbar_modules_selected) : images.tabbar_modules_grey}
            style={{ width: 25, height: 25 }} />,
        }}
      />
      <BottomTab.Screen
        name="TasksTab"
        component={TasksStackScreen}
        options={{
          title: i18n.t('BOTTOMTAB_TASKS_TITLE'),
          tabBarIcon: ({ focused }) => <Image
            source={focused ? (colorScheme === 'dark' ? images.tabbar_tasks_selected_dark : images.tabbar_tasks_selected) : images.tabbar_tasks_grey}
            style={{ width: 25, height: 25 }} />,
          tabBarBadge: taskCount > 0 ? taskCount : undefined, // undefined means hidden
          tabBarBadgeStyle: [
            { backgroundColor: Colors[colorScheme].task },
            // Workaround the fact that React Navigation's tab bar badge is fixed-width on Web
            // https://github.com/react-navigation/react-navigation/issues/10463
            Platform.OS === 'web' && { maxWidth: 'none' }
          ],
        }}
      />
      <BottomTab.Screen
        name="SupportTab"
        component={SupportStackScreen}
        options={{
          title: i18n.t('BOTTOMTAB_SUPPORT_TITLE'),
          tabBarIcon: ({ focused }) => <Image
            source={focused ? (colorScheme === 'dark' ? images.tabbar_support_selected_dark : images.tabbar_support_selected) : images.tabbar_support_grey}
            style={{ width: 25, height: 25 }} />,
        }}
      />
      <BottomTab.Screen
        name="AccountTab"
        component={AccountScreen}
        options={{
          title: i18n.t('BOTTOMTAB_ACCOUNT_TITLE'),
          tabBarIcon: ({ focused }) => <Image
            source={focused ? (colorScheme === 'dark' ? images.tabbar_account_selected_dark : images.tabbar_account_selected) : images.tabbar_account_grey}
            style={{ width: 25, height: 25 }} />,
          headerShown: true,
          headerShadowVisible: false, // hide divider line
        }}
      />
    </BottomTab.Navigator>
  );
}

/**
 * You can explore the built-in icon families and icons on the web at https://icons.expo.fyi/
 */
// function TabBarIcon(props: {
//   name: React.ComponentProps<typeof FontAwesome>['name'];
//   color: string;
// }) {
//   return <FontAwesome size={30} style={{ marginBottom: -3 }} {...props} />;
// }
