import { Ionicons } from '@expo/vector-icons';
import { useTheme } from '@react-navigation/native';
import { StyleSheet, View } from 'react-native';

import { styles as sharedLayoutStyles } from "../constants/Layout";
import { ListItemProps } from '../types';
import { MyPressable } from './MyComponents';
import { ThemedText } from './Themed';

export default function ListItem(props: ListItemProps) {
    const { colors } = useTheme();

    return <View style={sharedLayoutStyles.mobileContainer}>
        <MyPressable
            style={({ pressed }) => [
                styles.componentContainer,
                { backgroundColor: pressed ? colors.border : colors.background }]}
            onPress={() => props.onPress()}
        >
            {/* {({ pressed }) => */}
            <>
                <View style={styles.listItemSeparator} />
                <View style={styles.mainContent}>
                    <View style={styles.listItemIcon}><Ionicons name={props.iconName} size={24} color={colors.text} /></View>
                    <ThemedText style={styles.listItemTitle}>{props.title}</ThemedText>
                </View>
                <View style={[styles.listItemSeparator, { backgroundColor: colors.border }]} />
            </>
            {/* } */}
        </MyPressable>
    </View>;
}

const styles = StyleSheet.create({
    componentContainer: {
        height: 44,
        justifyContent: 'space-between',
    },
    listItemSeparator: {
        height: 1,
    },
    mainContent: {
        flexDirection: 'row',
        alignItems: 'center' // vertically align
    },
    listItemIcon: {
        width: 48,
        alignItems: 'center' // horizontally align
    },
    listItemTitle: {
        fontSize: 16,
        fontWeight: '500'
    },
});
