import { MaterialCommunityIcons } from '@expo/vector-icons';
import { useTheme } from '@react-navigation/native';
import i18n from 'i18n-js';
import { StyleSheet, View, ViewProps } from 'react-native';

import Colors from '../constants/Colors';
import useColorScheme from '../hooks/useColorScheme';
import { ThemedText } from './Themed';


export default function ModuleFooter(props: { online: boolean } & ViewProps) {
    const colorScheme = useColorScheme();
    const { colors } = useTheme();
    const { style, ...otherProps } = props;

    return <View style={style}>
        <View style={styles.wifiStatusRow}>
            {otherProps.online
                ?
                <>
                    <MaterialCommunityIcons name="wifi" size={24} color={colors.text} style={styles.wifiStatusIcon} />
                    <ThemedText>{i18n.t('MODULEDETAILSCREEN_STATUS_ONLINE')}</ThemedText>
                </>
                :
                <>
                    <MaterialCommunityIcons name="wifi-off" size={24} color={Colors[colorScheme].disabledText} style={styles.wifiStatusIcon} />
                    <ThemedText>{i18n.t('MODULEDETAILSCREEN_STATUS_OFFLINE')}</ThemedText>
                </>
            }
        </View>
    </View>;
}


const styles = StyleSheet.create({
    wifiStatusRow: {
        flexDirection: 'row',
        alignItems: 'center',
        marginVertical: 16,
    },
    wifiStatusIcon: {
        marginRight: 4,
    },
});
