import { useTheme } from '@react-navigation/native';
import { Platform, Switch, StyleSheet, Text, View } from 'react-native';

import colors from '../constants/Colors';


export function ThemedText(props: Text['props']) {
    const { style, ...otherProps } = props;
    const { colors } = useTheme();
    return <Text style={[{ color: colors.text }, style]} {...otherProps} />;
}

export function ThemedView(props: View['props']) {
    const { style, ...otherProps } = props;
    const { colors } = useTheme();
    return <View style={[{ backgroundColor: colors.background }, style]} {...otherProps} />;
}

export function ThemedSwitch(props: Switch['props']) {
    // const { style, ...otherProps } = props;
    const { colors } = useTheme();
    if (Platform.OS === 'web') {
        return <Switch
            // https://github.com/facebook/react-native/issues/30429
            // @ts-ignore
            activeThumbColor={colors.action}
            activeTrackColor="#547AFF"
            {...props}
        />
    }
    else {
        return <Switch {...props} />;
    }
}

// Couldn't pass through styles in Pressable subclass so using StyleSheet instead
// https://github.com/styled-components/styled-components/issues/3228
export const themedStyles = StyleSheet.create({
    actionPressable: {
        width: 116,
        height: 45,
        borderRadius: 25,
        alignItems: 'center',       // horizontal
        justifyContent: 'center',   // vertical
        backgroundColor: colors['light'].action,
    },
});
