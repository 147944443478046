import * as Analytics from 'expo-firebase-analytics';
import i18n from 'i18n-js';
import { useState } from 'react';
import { Image, Pressable, StyleSheet, Text, View } from 'react-native';

import images from '../assets/images';
import Colors from '../constants/Colors';
import { useAuth } from '../contexts/AuthContext';
import { useData } from '../contexts/DataContext';
import useColorScheme from '../hooks/useColorScheme';


export default function TaskListUnlockButton(props: { moduleId: string }) {
    // Call hooks
    const { authState } = useAuth();
    const colorScheme = useColorScheme();
    const { performUnlockDoors } = useData();
    const [isBusy, setBusy] = useState(false);

    async function onPress() {
        setBusy(true);
        if (authState?.userToken) {
            await performUnlockDoors(authState.userToken, props.moduleId);
        }
        setBusy(false);

        Analytics.logEvent('unlock_doors', {
            context: 'TaskList',
            moduleId: props.moduleId,
            nickname: authState.userObject.nickname,
        });
    }

    return <Pressable style={styles.containerRow}
        disabled={isBusy}
        onPress={async () => await onPress()}>
        {({ pressed }) =>
            <>
                <View style={styles.buttonIconView}>
                    <Image source={(isBusy || pressed) ? images.unlockdoors_pressed : (colorScheme === 'dark' ? images.unlockdoors_normal : images.unlockdoors_blue)}
                        style={styles.buttonIcon} />
                </View>
                <Text style={[
                    styles.buttonTitle,
                    { color: (isBusy || pressed) ? Colors[colorScheme].actionLinkHighlighted : Colors[colorScheme].actionLink }
                ]}>{i18n.t("TASKLIST_UNLOCK_BUTTON")}</Text>
            </>
        }
    </Pressable>;
}


const styles = StyleSheet.create({
    containerRow: {
        flexDirection: 'row',

        marginVertical: 16
    },
    buttonIconView: {
        width: 25,
        height: 25,
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: 10,

        // borderWidth: 1,
        // borderColor: 'red'
    },
    buttonIcon: {
        width: 18,
        height: 24
    },
    buttonTitle: {
        fontSize: 16,
        fontWeight: '400',
    }
});
