import i18n from 'i18n-js';
import { StyleSheet } from 'react-native';

import { styles as sharedLayoutStyles } from "../constants/Layout";
import { ThemedText, ThemedView } from './Themed';


export default function Error() {
    return <ThemedView style={[
        sharedLayoutStyles.rootContainer,
        sharedLayoutStyles.rootContentContainer,
        styles.loadingView
    ]}>
        <ThemedText>{i18n.t('ERROR_LOADING_TEXT')}</ThemedText>
    </ThemedView>;
}

export const styles = StyleSheet.create({
    loadingView: {
        justifyContent: 'center', // vertically
        textAlign: 'center',
    },
});
