import { Appearance, ColorSchemeName, useColorScheme as _useColorScheme } from 'react-native';

// The useColorScheme value is always either light or dark, but the built-in
// type suggests that it can be null. This will not happen in practice, so this
// makes it a bit easier to work with.
export default function useColorScheme(): NonNullable<ColorSchemeName> {
  let colorScheme = _useColorScheme();
  // Workaround useColorScheme() is not always up-to-date
  const colorScheme2 = Appearance.getColorScheme();
  if (colorScheme !== colorScheme2) {
    // console.warn(`useColorScheme() returned ${colorScheme}, overriding with ${colorScheme2}`);
    colorScheme = colorScheme2;
  }
  return colorScheme as NonNullable<ColorSchemeName>;
}
