import { Image, Platform } from 'react-native';

// "@2x" naming doesn't work with RN iOS ("Unable to resolve module")
const imageRequireSources = {
    growoff_logo: require('./GrowOff_Logo.png'),
    loading: require('./loading.gif'),
    loading_dark: require('./loading_dark.gif'),
    module_rendered: require('./module_rendered.png'),
    module_rendered_mini: require('./module_rendered_mini.png'),
    splash: require('./splash.png'),
    signin_module: require('./signin_module.png'),
    tabbar_account_grey: require('./tabbar_account_grey.png'),
    tabbar_account_selected_dark: require('./tabbar_account_selected_dark.png'),
    tabbar_account_selected: require('./tabbar_account_selected.png'),
    tabbar_modules_grey: require('./tabbar_modules_grey.png'),
    tabbar_modules_selected_dark: require('./tabbar_modules_selected_dark.png'),
    tabbar_modules_selected: require('./tabbar_modules_selected.png'),
    tabbar_support_grey: require('./tabbar_support_grey.png'),
    tabbar_support_selected_dark: require('./tabbar_support_selected_dark.png'),
    tabbar_support_selected: require('./tabbar_support_selected.png'),
    tabbar_tasks_grey: require('./tabbar_tasks_grey.png'),
    tabbar_tasks_selected_dark: require('./tabbar_tasks_selected_dark.png'),
    tabbar_tasks_selected: require('./tabbar_tasks_selected.png'),
    task_harvest_white: require('./task_harvest_white.png'),
    task_harvest: require('./task_harvest.png'),
    task_refilltank_white: require('./task_refilltank_white.png'),
    task_refilltank: require('./task_refilltank.png'),
    task_removefilm_white: require('./task_removefilm_white.png'),
    task_removefilm: require('./task_removefilm.png'),
    task_clean_position_white: require('./task_clean_position_white.png'),
    task_clean_position: require('./task_clean_position.png'),
    task_contact_us_white: require('./task_contact_us_white.png'),
    task_contact_us: require('./task_contact_us.png'),
    unlockdoors_blue: require('./unlockdoors_blue.png'),
    unlockdoors_disabled_sm: require('./unlockdoors_disabled_sm.png'),
    unlockdoors_disabled_dark_sm: require('./unlockdoors_disabled_dark_sm.png'),
    unlockdoors_normal: require('./unlockdoors_normal.png'),
    unlockdoors_normal_sm: require('./unlockdoors_normal_sm.png'),
    unlockdoors_pressed: require('./unlockdoors_pressed.png'),
};

export const imageNamesToPreload = [
    'splash', // used as background on SignInScreen
    'module_rendered', 'module_rendered_mini',
    'tabbar_account_grey', 'tabbar_modules_grey', 'tabbar_support_grey', 'tabbar_tasks_grey',
    'task_harvest_white', 'task_harvest',
    'task_refilltank_white', 'task_refilltank',
    'task_removefilm_white', 'task_removefilm'
];

const images = Object.fromEntries(Object.entries(imageRequireSources)
    .map(([k, v]) => {
        // https://github.com/expo/expo/issues/10737#issuecomment-772587125
        if (Platform.OS === "web") {
            return [k, { uri: v }];
        }
        else {
            return [k, Image.resolveAssetSource(v)];
        }
    }));

export default images;
