// Same as react-native-radio-buttons-group/RadioButton
// but uses ThemedText, ThemedView
// and draws the label BEFORE the radio control

import React from 'react';
import { PixelRatio, Pressable, StyleSheet } from 'react-native';
import { RadioButtonProps } from 'react-native-radio-buttons-group';

import { ThemedText, ThemedView } from '../components/Themed';


export default function ModuleCommandRadioButton({
    borderColor,
    color = '#444',
    containerStyle,
    description,
    descriptionStyle,
    disabled = false,
    id,
    label,
    labelStyle,
    layout = 'row',
    onPress,
    selected = false,
    size = 24,
    borderSize = 2,
}: RadioButtonProps) {

    const borderWidth = PixelRatio.roundToNearestPixel(borderSize);
    const sizeHalf = PixelRatio.roundToNearestPixel(size * 0.5);
    const sizeFull = PixelRatio.roundToNearestPixel(size);

    let orientation: any = { flexDirection: 'row' };
    let margin: any = { marginLeft: 10 };

    if (layout === 'column') {
        orientation = { alignItems: 'center' };
        margin = { marginTop: 10 };
    }

    function handlePress() {
        if (disabled) {
            return null;
        }
        if (onPress) {
            onPress(id);
        }
    }

    return (
        <>
            <Pressable
                onPress={handlePress}
                style={[
                    styles.container,
                    orientation,
                    { opacity: disabled ? 0.2 : 1 },
                    containerStyle,
                ]}>
                {Boolean(label) && <ThemedText style={[margin, labelStyle]}>{label}</ThemedText>}
                <ThemedView
                    style={[
                        styles.border,
                        {
                            borderColor: borderColor || color,
                            borderWidth,
                            width: sizeFull,
                            height: sizeFull,
                            borderRadius: sizeHalf,
                        },
                    ]}>
                    {selected && (
                        <ThemedView
                            style={{
                                backgroundColor: color,
                                width: sizeHalf,
                                height: sizeHalf,
                                borderRadius: sizeHalf,
                            }}
                        />
                    )}
                </ThemedView>
            </Pressable>
            {Boolean(description) && <ThemedText style={[margin, descriptionStyle]}>{description}</ThemedText>}
        </>
    );
}

const styles = StyleSheet.create({
    container: {
        alignItems: 'center',
        marginHorizontal: 10,
        marginVertical: 5,
    },
    border: {
        justifyContent: 'center',
        alignItems: 'center',
    },
});
