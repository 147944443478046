import { StyleSheet, Text, View, ViewProps } from 'react-native';

import { Brand } from '../constants/Colors';


export default function TasksCountBadge(props: { count: number } & ViewProps) {
    const { style } = props;
    if (props.count) {
        return <View style={[style, styles.badgeView]}>
            <Text style={styles.badgeText}>{props.count}</Text>
        </View>;
    }
    else {
        return <></>;
    }
}

const styles = StyleSheet.create({
    badgeView: {
        minWidth: 18,
        height: 18,
        backgroundColor: Brand.green,
        borderRadius: 50,
        justifyContent: 'center', // vertically
        alignItems: 'center',   // horizontally
    },
    badgeText: {
        fontSize: 13,
        fontWeight: '600',
        paddingHorizontal: 4
    }
});
