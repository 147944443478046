/**
 * Learn more about deep linking with React Navigation
 * https://reactnavigation.org/docs/deep-linking
 * https://reactnavigation.org/docs/configuring-links
 */

import { LinkingOptions } from '@react-navigation/native';
import * as Linking from 'expo-linking';

import { RootStackParamList } from '../types';

const linking: LinkingOptions<RootStackParamList> = {
  prefixes: [Linking.createURL('/')],
  config: {
    screens: {
      SignIn: {
        path: ''
      },
      Root: {
        screens: {
          ModulesTab: {
            screens: {
              ModulesList: {
                path: 'modules'
              },
              ModulesDetail: {
                path: 'modules/:moduleId'
              },
            },
          },
          TasksTab: {
            screens: {
              TasksOverview: {
                path: 'tasks'
              },
              TasksModuleList: {
                path: 'tasks/:taskType'
              },
              TasksDetail: {
                path: 'tasks/:taskType/:moduleId'
              },
            },
          },
          SupportTab: {
            screens: {
              SupportIndex: 'support',
            },
          },
          AccountTab: {
            screens: {
              AccountScreen: 'account',
            },
          },
        },
      },
      NotFound: '*',
    },
  },
};

export default linking;
