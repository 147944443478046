import { Ionicons } from '@expo/vector-icons';
import { useNavigation, useTheme } from '@react-navigation/native';
import { StyleSheet, View } from 'react-native';

import { styles as sharedLayoutStyles } from "../constants/Layout";
import { ModulesListScreenProps, Module } from '../types';
import ModuleUnlockButton from "./ModuleUnlockButton";
import { MyPressable } from './MyComponents';
import { ThemedText } from './Themed';


export default function ModuleListCard(props: { module: Module }) {
    const { colors } = useTheme()
    // https://reactnavigation.org/docs/typescript/#annotating-usenavigation
    const navigation = useNavigation<ModulesListScreenProps['navigation']>();

    return <View style={sharedLayoutStyles.mobileContainer}>
        <MyPressable
            style={({ pressed }) => [
                sharedLayoutStyles.cardView,
                styles.cardContainer,
                { backgroundColor: pressed ? colors.border : colors.card },
            ]}
            onPress={() => {
                navigation.navigate('ModulesDetail', { moduleId: props.module.moduleId })
            }}
        >
            <View style={styles.contentRow}>
                <ThemedText style={styles.moduleTitle}>{props.module.fullName}</ThemedText>
                <Ionicons name="chevron-forward-outline" size={24} color={colors.text} />
            </View>
            <View style={styles.accessoryRow}>
                <ModuleUnlockButton moduleId={props.module.moduleId} isSmall online={props.module.online} />
            </View>
        </MyPressable >
    </View >
}


const styles = StyleSheet.create({
    cardContainer: {
        padding: 16,

        borderRadius: 4,

        shadowOffset: {
            width: 0,       // X
            height: 2,      // Y
        },
        shadowRadius: 8,    // B
        shadowColor: '#767676',
        shadowOpacity: 0.1,
    },
    contentRow: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    moduleTitle: {
        fontSize: 20,
        fontWeight: '500'
    },
    accessoryRow: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingTop: 8
    },
});
