import i18n from 'i18n-js';
import React, { useEffect, useState } from 'react';

import { growoffApiBaseUrl } from '../environment';
import { Module, Cassette, Tank, Task, Crop, Site, GrowLightsValue, DataContextType, PosError } from "../types";
import { useAuth } from './AuthContext';


const API_CROPS_URL = `${growoffApiBaseUrl}/crops`;
const API_MODULES_URL = `${growoffApiBaseUrl}/modules`;
const API_SITES_URL = `${growoffApiBaseUrl}/sites`;


/* Helpers */

export function CassetteDescription(c: Cassette) {
    return `${c.floor}:${c.pos} ${i18n.t('CROP_NAME_' + c.cropCode)}`;
}

export function TankDescription(tank: Tank) {
    switch (tank.tankType) {
        case 'RED':
            return i18n.t('MODULETANK_RED');
        case 'BLUE':
            return i18n.t('MODULETANK_BLUE');
        case 'YELLOW':
            return i18n.t('MODULETANK_YELLOW');
    }
}

export function ErrorDescription(e: PosError) {
    if (e.errorCode & 1)
        return `${e.floor}:${e.pos} ${i18n.t('TASKTYPE_CONTACT_US')}`;
    else (e.errorCode > 0)
        return `${e.floor}:${e.pos} ${i18n.t('TASKTYPE_CLEAN_POSITION')}`;
}

/* Data Context */

const DataContext = React.createContext<DataContextType | undefined>(undefined);

type ModulesDataProviderProps = React.PropsWithChildren<object>;

export function DataProvider({ children }: ModulesDataProviderProps) {
    const { authState } = useAuth();
    // TODO: consider moving from useState() to useReducer()
    const [crops, setCrops] = useState<Crop[] | null>(null);
    const [modules, setModules] = useState<Module[] | null>(null);
    const [sites, setSites] = useState<Site[] | null>(null);
    const [isLoading, setLoading] = useState(true);
    const [tasks, setTasks] = useState<Task[]>([]);
    const [checkedTasks, setCheckedTasks] = useState<Task[]>([]);

    function _processModules(modules: Module[]) {
        // Add computed properties here
        for (const m of modules) {
            m.cassettesByFP = {};
            m.cassettes?.forEach(cassette => {
                const key = `${cassette.floor}:${cassette.pos}`;
                m.cassettesByFP[key] = cassette;
            });

            m.tanks = {
                // This is the presentation order [GOSW-416]
                RED: { tankType: 'RED', isDry: !m.redadditivetankBottomWet },
                BLUE: { tankType: 'BLUE', isDry: !m.blueadditivetankBottomWet },
                YELLOW: { tankType: 'YELLOW', isDry: !m.phadjustertankBottomWet },
            }

            m.fullName = [m.siteName, m.moduleName].join(' ');
        }
    }

    function _createTasksWithModules(modules: Module[]) {
        const outTasks: Task[] = []

        for (const m of modules) {
            // Check all cassettes for tasks
            for (const c of Object.values(m.cassettesByFP)) {
                switch (c.cassetteState) {
                    case 'REMOVE_FILM':
                        outTasks.push({
                            taskType: 'remove_film',
                            module: m,
                            cassette: c,
                            description: CassetteDescription(c)
                        });
                        break;
                    case 'READY_FOR_HARVEST':
                        outTasks.push({
                            taskType: 'harvest',
                            module: m,
                            cassette: c,
                            description: CassetteDescription(c)
                        });
                        break;
                }
            }

            // Check all tanks for tasks
            for (const t of Object.values(m.tanks)) {
                if (t.isDry) {
                    outTasks.push({
                        taskType: 'refill_tank',
                        module: m,
                        tank: t,
                        description: TankDescription(t)
                    })
                }
            }

            // Check all positions for errors
            for (const e of Object.values(m.errors)) {
                if (e.errorCode & 1) {
                    outTasks.push({
                        taskType: 'contact_us',
                        module: m,
                        error: e,
                        description: ErrorDescription(e)
                    })
                }
                else if (e.errorCode > 0 && m.cassettesByFP[e.floor + ':' + e.pos]) {
                    outTasks.push({
                        taskType: 'clean_position',
                        module: m,
                        error: e,
                        description: ErrorDescription(e)
                    })
                }
            }
        }

        return outTasks;
    }

    function _addTranslationsFromCrops(crops: Crop[]) {
        const cropTranslations: Record<string, Record<string, string>> = {};
        for (const crop of crops) {
            const stringKey = `CROP_NAME_${crop.cropCode}`;
            for (const langCode in crop.names) {
                let langDict = cropTranslations[langCode];
                if (!langDict) {
                    langDict = {}
                    cropTranslations[langCode] = langDict;
                }
                langDict[stringKey] = crop.names[langCode];
            }
        }

        // Deeply merge key-value properties from two objects
        function merge(a: any, b: any) {
            for (const key in b) {
                if (!(key in a)) a[key] = b[key];
                else merge(a[key], b[key]);
            }
            return a;
        }
        i18n.translations = merge(i18n.translations, cropTranslations);
        // console.debug(i18n.translations);
    }

    // https://www.mariokandut.com/how-to-fetch-data-with-react-hooks/
    // https://stackoverflow.com/a/57846330/594211
    useEffect(() => {
        // console.debug('DataContext useEffect()');

        const fetchCropsData = async () => {
            if (!authState.userToken) {
                return; // not logged in, so don't do anything
            }

            try {
                console.log(`Fetching ${API_CROPS_URL}`);
                const response = await fetch(API_CROPS_URL, {
                    headers: { 'Authorization': `Bearer ${authState.userToken}` }
                });

                const crops: Crop[] = await response.json();
                setCrops(crops);

                _addTranslationsFromCrops(crops);
            }
            catch (e) {
                console.error(e);
            }
        }

        const fetchModulesData = async () => {
            if (!authState.userToken) {
                return; // not logged in, so don't do anything
            }

            try {
                setLoading(true);

                console.log(`Fetching ${API_MODULES_URL}`);
                const response = await fetch(API_MODULES_URL, {
                    headers: { 'Authorization': `Bearer ${authState.userToken}` }
                });

                const modules: Module[] = await response.json();
                // console.debug(modules);
                _processModules(modules);

                setModules(modules);
                setTasks(_createTasksWithModules(modules));
                setCheckedTasks([]);
            }
            catch (e) {
                console.error(e);
            }
            finally {
                setLoading(false);
            }
        };

        const fetchSitesData = async () => {
            if (!authState.userToken) {
                return; // not logged in, so don't do anything
            }

            try {
                console.log(`Fetching ${API_SITES_URL}`);
                const response = await fetch(API_SITES_URL, {
                    headers: { 'Authorization': `Bearer ${authState.userToken}` }
                });

                const sites: Site[] = await response.json();
                setSites(sites);
            }
            catch (e) {
                console.error(e);
            }
        }

        fetchCropsData();
        fetchModulesData();
        fetchSitesData();

        // Refresh modules data every 10 minutes, see [GOSW-625]
        const intervalID = window.setInterval(async () => {
            fetchCropsData();
            fetchModulesData();
            fetchSitesData();
        }, 1000 * 60 * 10);

        // https://reactjs.org/docs/hooks-effect.html#effects-with-cleanup
        return function cleanup() {
            // console.debug('DataContext cleanup()');

            window.clearInterval(intervalID);
        };
    }, [authState.userToken]); // https://reactjs.org/docs/hooks-faq.html#what-can-i-do-if-my-effect-dependencies-change-too-often


    /* Commands */

    async function _sendModuleCommand(moduleId: string, command: string, authToken?: string): Promise<boolean> {
        console.log(`Sending command "${command}"`)

        const url = `${growoffApiBaseUrl}/modules/${moduleId}`;
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${authToken}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ command })
        });

        // await new Promise(r => setTimeout(r, 3000)); // i.e. sleep()

        if (!response.ok) {
            const message = `Error: ${response.status}`;
            console.error(message);
            return false;
        }

        return true;
    }

    async function performUnlockDoors(authToken: string, moduleId: string) {
        await _sendModuleCommand(moduleId, "open_door", authToken);
    }

    async function performSetGrowLights(authToken: string, module: Module, value: GrowLightsValue) {
        switch (value) {
            case GrowLightsValue.On:
                await _sendModuleCommand(module.moduleId, "grow_lights_on", authToken);
                break
            case GrowLightsValue.Off:
                await _sendModuleCommand(module.moduleId, "grow_lights_off", authToken);
                break
            case GrowLightsValue.Dim:
                await _sendModuleCommand(module.moduleId, "grow_lights_dim", authToken);
                break
        }

        module.growlightsOn = (value !== GrowLightsValue.Off);
        module.growlightsIntensity = (value === GrowLightsValue.Off ? 0.0 : (value === GrowLightsValue.Dim ? 0.05 : 0.7));
    }

    async function performSetWateringEnabled(authToken: string, module: Module, value: boolean) {
        await _sendModuleCommand(module.moduleId, value ? "enable_watering" : "disable_watering", authToken);
        module.wateringEnabled = value;
    }

    async function performRemoveFilmCassetteTask(authToken: string, task: Task) {
        const cassette = task.cassette!;
        if (cassette.cassetteState === 'REMOVE_FILM') {
            await _sendModuleCommand(task.module.moduleId, `remove_film${cassette.floor}_${cassette.pos}`, authToken);

            if (!checkedTasks.includes(task)) {
                checkedTasks.push(task);
                setCheckedTasks([...checkedTasks]); // https://stackoverflow.com/a/56266640/594211
            }
        }
        else {
            console.error(`Attempt to remove film on a cassette with state=${cassette.cassetteState}, expected REMOVE_FILM`);
        }
    }

    async function performHarvestCassetteTask(authToken: string, task: Task) {
        const cassette = task.cassette!;
        if (cassette.cassetteState === 'READY_FOR_HARVEST') {
            await _sendModuleCommand(task.module.moduleId, `harvest${cassette.floor}_${cassette.pos}`, authToken);

            if (!checkedTasks.includes(task)) {
                checkedTasks.push(task);
                setCheckedTasks([...checkedTasks]); // https://stackoverflow.com/a/56266640/594211
            }
        }
        else {
            console.error(`Attempt to harvest cassette with state=${cassette.cassetteState}, expected READY_FOR_HARVEST`);
        }
    }

    async function performRefillTankTask(authToken: string, task: Task) {
        const tank = task.tank!;
        switch(tank.tankType)
        {
            case 'RED':
                await _sendModuleCommand(task.module.moduleId, 'red_nutrient_tank_filled', authToken);
                break;
            case 'BLUE':
                await _sendModuleCommand(task.module.moduleId, 'blue_nutrient_tank_filled', authToken);
                break;
            case 'YELLOW':
                await _sendModuleCommand(task.module.moduleId, 'ph_adjuster_tank_filled', authToken);
                break;
        }

        if (!checkedTasks.includes(task)) {
            checkedTasks.push(task);
            setCheckedTasks([...checkedTasks]); // https://stackoverflow.com/a/56266640/594211
        }
    }

    return (
        <DataContext.Provider value={{
            crops,
            modules,
            sites,
            isLoading,
            tasks,
            checkedTasks,
            performUnlockDoors,
            performSetGrowLights,
            performSetWateringEnabled,
            performRemoveFilmCassetteTask,
            performHarvestCassetteTask,
            performRefillTankTask
        }}>
            {children}
        </DataContext.Provider>
    );
}

export function useData() {
    const context = React.useContext(DataContext)
    if (context === undefined) {
        throw new Error('useData must be used within a DataProvider')
    }
    return context
}
