import { useNavigation } from '@react-navigation/native';
import i18n from 'i18n-js';
import { StyleSheet, Text, View } from 'react-native';

import Colors from '../constants/Colors';
import useColorScheme from '../hooks/useColorScheme';
import { TasksOverviewScreenProps } from '../types';
import { MyPressable } from './MyComponents';
import { themedStyles } from './Themed';


export default function ModuleUnlockButton() {
    // Call hooks
    const colorScheme = useColorScheme();
    const navigation = useNavigation<TasksOverviewScreenProps['navigation']>();

    return (
        <MyPressable
            style={({ pressed }) => [
                themedStyles.actionPressable,
                { backgroundColor: Colors[colorScheme].action },
                pressed && { backgroundColor: Colors[colorScheme].actionHighlighted }
            ]}
            onPress={() => {
                navigation.navigate('TasksOverview')
            }} >
            {({ pressed }) =>
                <View style={styles.mainRow}>
                    <Text style={[
                        styles.buttonTitle,
                        { color: pressed ? Colors[colorScheme].actionTitleHighlighted : Colors[colorScheme].actionTitle }
                    ]}>{i18n.t("TASKLIST_DONE_BUTTON")}</Text>
                </View>
            }
        </MyPressable >
    );
}


const styles = StyleSheet.create({
    mainRow: {
        flexDirection: 'row',
        alignItems: 'center',   // vertically center
    },
    buttonTitle: {
        fontSize: 16,
        fontWeight: '500',
        marginLeft: 6,
    },
});
