
import { useNavigation } from '@react-navigation/native';
import i18n from 'i18n-js';
import React from 'react';
import { ImageBackground, ScrollView, StyleSheet, View } from 'react-native';

import images from '../assets/images';
import Callout from '../components/Callout';
import Error from '../components/Error';
import Loading from '../components/Loading';
import ModuleFooter from "../components/ModuleFooter";
import ModuleOverlay from "../components/ModuleOverlay";
import TasksDoneButton from '../components/TaskListDoneButton';
import TaskListItem from '../components/TaskListItem';
import TaskListUnlockButton from '../components/TaskListUnlockButton';
import TasksDetailFooterLink from '../components/TasksDetailFooterLink';
import { ThemedText } from '../components/Themed';
import { styles as sharedLayoutStyles } from "../constants/Layout";
import { useData } from '../contexts/DataContext';
import { TasksDetailScreenProps } from "../types";


/*
    The detail screen for a single module and a single task type.
    (i.e. 1 task type, 1 module)
*/
export default function TasksDetailScreen(props: TasksDetailScreenProps) {
    const { modules, isLoading, tasks } = useData();
    // https://reactnavigation.org/docs/typescript/#annotating-usenavigation
    const navigation = useNavigation<TasksDetailScreenProps['navigation']>();

    const module = modules?.find(x => x.moduleId === props.route.params.moduleId);
    const moduleTasks = tasks?.filter(t => t.module?.moduleId === props.route.params.moduleId);

    React.useLayoutEffect(() => {
        navigation.setOptions({
            title: module?.fullName,
            // note: https://github.com/necolas/react-native-web/issues/1336
            // "ellipsizeMode: middle will also not work as expected on web platforms as there is no CSS equivalent"
        });
    }, [navigation, module]);

    if (module && moduleTasks) {
        const cassetteTasks = moduleTasks
            .filter(t => t.cassette && t.taskType === props.route.params.taskType)
            .sort((a, b) => a.description.localeCompare(b.description))
        const tankTasks = moduleTasks.filter(t => t.tank);

        let headerText = '';
        let subheaderText = '';
        let taskLinkText = '';
        let taskLinkUrl = '';

        switch (props.route.params.taskType) {
            case 'harvest':
                headerText = i18n.t('TASKTYPE_HARVEST');
                subheaderText = i18n.t('TASKSDETAILSCREEN_HARVEST_SUBHEADER_TEXT');
                taskLinkText = i18n.t('TASKSDETAILSCREEN_HARVEST_HOWTO_LINK');
                taskLinkUrl = i18n.t('TASKSDETAILSCREEN_HARVEST_HOWTO_URL');
                break;
            case 'remove_film':
                headerText = i18n.t('TASKTYPE_REMOVE_FILM');
                subheaderText = i18n.t('TASKSDETAILSCREEN_REMOVEFILM_SUBHEADER_TEXT');
                taskLinkText = i18n.t('TASKSDETAILSCREEN_REMOVEFILM_HOWTO_LINK');
                taskLinkUrl = i18n.t('TASKSDETAILSCREEN_REMOVEFILM_HOWTO_URL');
                break;
            case 'refill_tank':
                headerText = i18n.t('TASKTYPE_REFILL_TANK');
                subheaderText = i18n.t('TASKSDETAILSCREEN_REFILLTANK_SUBHEADER_TEXT');
                taskLinkText = i18n.t('TASKSDETAILSCREEN_REFILLTANK_HOWTO_LINK');
                taskLinkUrl = i18n.t('TASKSDETAILSCREEN_REFILLTANK_HOWTO_URL');
                break;
            case 'clean_position':
                headerText = i18n.t('TASKTYPE_CLEAN_POSITION');
                subheaderText = i18n.t('TASKSDETAILSCREEN_CLEAN_POSITION_SUBHEADER_TEXT');
                taskLinkText = i18n.t('TASKSDETAILSCREEN_CLEAN_POSITION_HOWTO_LINK');
                taskLinkUrl = i18n.t('TASKSDETAILSCREEN_CLEAN_POSITION_HOWTO_URL');
                break;
            case 'contact_us':
                headerText = i18n.t('TASKTYPE_CONTACT_US');
                subheaderText = i18n.t('TASKSDETAILSCREEN_CONTACT_US_SUBHEADER_TEXT');
                taskLinkText = i18n.t('TASKSDETAILSCREEN_CONTACT_US_HOWTO_LINK');
                taskLinkUrl = i18n.t('TASKSDETAILSCREEN_CONTACT_US_HOWTO_URL');
                break;
            }

        return <ScrollView style={sharedLayoutStyles.rootContainer}
            contentContainerStyle={sharedLayoutStyles.rootContentContainer}>
            <View style={sharedLayoutStyles.mobileContainer}>
                <View style={sharedLayoutStyles.paddedContent}>
                    <View style={styles.detailHeaderView}>
                        {module.online
                            ?
                            <>
                                <ThemedText style={styles.taskHeaderText}>{headerText}</ThemedText>
                                <ThemedText style={styles.taskSubheaderText}>{subheaderText}</ThemedText>

                                <TaskListUnlockButton moduleId={module.moduleId} />
                                <View style={styles.taskListView}>
                                    {props.route.params.taskType === 'refill_tank'
                                        ?
                                        tankTasks.map((task, index) =>
                                            <TaskListItem key={index} task={task} text={task.description} />)
                                        :
                                        cassetteTasks.map((task, index) =>
                                            <TaskListItem key={index} task={task} text={task.description} />)
                                    }
                                </View>
                                <View style={styles.tasksDoneButtonView}>
                                    <TasksDoneButton />
                                </View>
                            </>
                            :
                            <Callout
                                title={i18n.t('TASKSDETAILSCREEN_OFFLINE_CALLOUT_HEADERTEXT')}
                                body={i18n.t('TASKSDETAILSCREEN_OFFLINE_CALLOUT_TEXT')} />
                        }
                    </View>

                    <ImageBackground source={images.module_rendered}
                        style={styles.imageBackground}
                        imageStyle={{ opacity: module.online ? 1.0 : 0.5 }}
                    >
                        <ModuleOverlay module={module} moduleTasks={moduleTasks} highlightTaskType={props.route.params.taskType} />
                        <View style={sharedLayoutStyles.mobileContainer}>
                            <View style={sharedLayoutStyles.paddedContent}>
                                <ModuleFooter style={styles.moduleFooter} online={module.online} />
                            </View>
                        </View>
                    </ImageBackground>

                    <View style={styles.detailFooterView}>
                        <TasksDetailFooterLink iconName="book-outline" text={taskLinkText} url={taskLinkUrl} />
                        <TasksDetailFooterLink iconName="alert-circle-outline" text={i18n.t('TASKSDETAILSCREEN_REPORT_ISSUE_LINK')} url="https://gronska.atlassian.net/servicedesk/customer/portal/1/group/1/create/6" />
                    </View>
                </View>
            </View>
        </ScrollView>;
    }
    else {
        return isLoading ? <Loading /> : <Error />;
    }
}


const styles = StyleSheet.create({
    detailHeaderView: {
        marginTop: 8,
        marginBottom: 16,

        // borderWidth: 1,
        // borderColor: 'red'
    },
    taskListView: {
        // borderWidth: 1,
        // borderColor: 'blue'
    },
    tasksDoneButtonView: {
        marginVertical: 16,
    },
    taskHeaderText: {
        fontSize: 26,
        fontWeight: '500',
        marginBottom: 8,
    },
    taskSubheaderText: {
        fontSize: 16,
        fontWeight: '400',
        marginBottom: 8,
    },
    imageBackground: {
        width: 960 / 2,
        height: 1068 / 2,
        marginLeft: -((960 / 2 - 320) / 2 + 8),
        alignItems: 'center',

        // borderWidth: 1,
        // borderColor: 'red'
    },
    moduleFooter: {
        marginBottom: 16,
    },
    detailFooterView: {
        marginTop: 8,
    },
});
