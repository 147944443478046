import { StyleSheet, Animated, Easing } from 'react-native';
import React, { useEffect, useRef } from 'react';
import Colors from '../constants/Colors';
import useColorScheme from '../hooks/useColorScheme';

export default function ModulePositionErrorIndicator(props: { color?: string }) {
    const colorScheme = useColorScheme();
    const opacity = useRef(new Animated.Value(1)).current;

    useEffect(() => {
        const blinkAnimation = Animated.loop(
            Animated.sequence([
                Animated.timing(opacity, {
                    toValue: 0.3,
                    duration: 1000,
                    easing: Easing.inOut(Easing.ease), // Använd en icke-linjär easing-funktion
                    useNativeDriver: true,
                }),
                Animated.timing(opacity, {
                    toValue: 1,
                    duration: 1000,
                    easing: Easing.inOut(Easing.ease), // Använd en icke-linjär easing-funktion
                    useNativeDriver: true,
                }),
            ])
        );
        blinkAnimation.start();

        // Cleanup the animation on unmount
        return () => blinkAnimation.stop();
    }, [opacity]);

    return (
        <Animated.View style={[
            styles.component,
            { backgroundColor: props.color ?? Colors[colorScheme].errorIndicatorOverlay, opacity }
        ]} />
    );
}

const styles = StyleSheet.create({
    component: {
        width: 8,
        height: 8,
        borderRadius: 50,
        marginHorizontal: 2,
    },
});