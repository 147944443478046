import { Ionicons } from '@expo/vector-icons';
import { useNavigation, useTheme } from '@react-navigation/native';
import i18n from 'i18n-js';
import { StyleSheet, View } from 'react-native';

import { styles as sharedLayoutStyles } from "../constants/Layout";
import { TankDescription, useData } from '../contexts/DataContext';
import { TasksModuleListScreenProps, Module, Cassette, TaskType, Task, TaskTypeToCassetteState } from '../types';
import ModuleMini from './ModuleMini';
import { MyPressable } from './MyComponents';
import TasksCountBadge from './TasksCountBadge';
import { ThemedText } from './Themed';


export default function TasksModuleListItem(props: { taskType: TaskType, module: Module, moduleTasks: Task[] }) {
    const { checkedTasks } = useData();
    // https://reactnavigation.org/docs/typescript/#annotating-usenavigation
    const navigation = useNavigation<TasksModuleListScreenProps['navigation']>();
    const { colors } = useTheme();

    const highlightCassetteState = (props.taskType && TaskTypeToCassetteState(props.taskType)) ?? null;
    const showCassettes = props.moduleTasks.filter(t => t.cassette)
        .filter(t => !checkedTasks.includes(t))
        .filter(t => !props.taskType || t.cassette!.cassetteState === highlightCassetteState)
        .map(t => t.cassette!);
    const showTanks = props.moduleTasks.filter(t => t.tank)
        .filter(t => !checkedTasks.includes(t))
        .filter(t => !props.taskType || props.taskType === 'refill_tank')
        .map(t => t.tank!);

    //     const taskCassettes = props.moduleTasks
    //     .filter(t => t.taskType === props.taskType && t.cassette)
    //     .map(mt => mt.cassette!);
    // const taskTanks = props.moduleTasks.filter(t => t.tank).map(t => t.tank!);
    const taskCount = showCassettes.length + showTanks.length;

    let subtitle;
    if (props.module.online) {
        if (showCassettes.length > 0) {
            // Group cassettes by crop type to build subtitle string e.g. "Sage (1), Basil (3)"
            const cropCodeToCassettes: Record<string, Cassette[]> = {};
            for (const cassette of Object.values(showCassettes)) {
                let cassettes = cropCodeToCassettes[cassette.cropCode];
                if (!cassettes) {
                    cassettes = []
                    cropCodeToCassettes[cassette.cropCode] = cassettes;
                }
                cassettes.push(cassette);
            }
            subtitle = Object.keys(cropCodeToCassettes)
                .map(cropCode => `${i18n.t('CROP_NAME_' + cropCode)} (${cropCodeToCassettes[cropCode].length})`)
                .join('\n');
        }

        if (showTanks.length > 0) {
            subtitle = showTanks.map(tank => TankDescription(tank))
                .join('\n');
        }
    }
    else {
        subtitle = i18n.t('TASKSMODULELISTITEM_OFFLINE_SUBTITLE');
    }

    return <View style={sharedLayoutStyles.mobileContainer}>
        <MyPressable
            style={({ pressed }) => [
                styles.componentContainer,
                { backgroundColor: pressed ? colors.border : colors.background },
            ]}
            onPress={() => {
                navigation.navigate('TasksDetail', { taskType: props.taskType, moduleId: props.module.moduleId })
            }}
        >
            {/* {({ pressed }) => */}
            <>
                <View style={styles.listItemSeparator} />
                <View style={styles.mainContent}>
                    <View style={styles.miniModuleColumn}>
                        <ModuleMini module={props.module} moduleTasks={props.moduleTasks}
                            highlightTaskType={props.taskType} />
                    </View>
                    <View style={styles.moduleInfoColumn}>
                        <View style={styles.moduleNameRow}>
                            <ThemedText style={styles.moduleTitle}>{props.module.fullName}</ThemedText>
                            {(props.module.online) &&
                                <TasksCountBadge style={styles.tasksCountBadge} count={taskCount} />
                            }
                            <Ionicons name="chevron-forward-outline" size={24} color={colors.text} />
                        </View>
                        <ThemedText style={styles.moduleSubtitle}>{subtitle}</ThemedText>
                    </View>
                </View>
                <View style={[styles.listItemSeparator, { backgroundColor: colors.border }]} />
            </>
            {/* } */}
        </MyPressable>
    </View>;
}

const styles = StyleSheet.create({
    componentContainer: {
        justifyContent: 'space-between',
    },
    listItemSeparator: {
        height: 1,
    },
    mainContent: {
        flexDirection: 'row',
        paddingTop: 16,

        // borderWidth: 1,
        // borderColor: 'red'
    },
    miniModuleColumn: {
        // borderWidth: 1,
        // borderColor: 'red'
    },
    moduleInfoColumn: {
        flex: 1,

        marginLeft: 8,

        // borderWidth: 1,
        // borderColor: 'red'
    },
    moduleNameRow: {
        flexDirection: 'row',
        alignItems: 'flex-end', // vertically
        justifyContent: 'space-between',
    },
    tasksCountBadge: {
        marginLeft: 4,
        marginBottom: 5,
    },
    moduleTitle: {
        flex: 1,
        fontSize: 20,
        fontWeight: '500'
    },
    moduleSubtitle: {
        marginTop: 16,

        fontSize: 16,
        fontWeight: '400'
    }
});
