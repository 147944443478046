import i18n from 'i18n-js';
import { StyleSheet, View } from 'react-native';

import Colors from '../constants/Colors';
import { styles as sharedLayoutStyles } from "../constants/Layout";
import { useData } from '../contexts/DataContext';
import useColorScheme from '../hooks/useColorScheme';
import { ModuleRenderedProps, Cassette, TaskType, TaskTypeToCassetteState } from '../types';
import ModuleCassetteLabel from './ModuleCassetteLabel';
import ModuleCassetteTaskIndicator from './ModuleCassetteTaskIndicator';
import ModulePositionErrorIndicator from './ModulePositionErrorIndicator';
import ModulePositionDisabledIndicator from './ModulePositionDisabledIndicator';


function ShouldDimCassette(cassette: Cassette, highlightTaskType?: TaskType) {
    let shouldDim = false;
    const highlightCassetteState = (highlightTaskType && TaskTypeToCassetteState(highlightTaskType)) ?? null;
    switch (highlightTaskType) {
        case 'remove_film':
        case 'harvest':
            if (cassette?.cassetteState !== highlightCassetteState) {
                shouldDim = true;
            }
            break;
        case 'refill_tank':
        case 'clean_position':
        case 'contact_us':
            shouldDim = true;
            break;
    }
    return shouldDim;
}

export default function ModuleOverlay(props: ModuleRenderedProps) {
    const colorScheme = useColorScheme();
    const { checkedTasks } = useData();

    const highlightCassetteState = props.highlightTaskType && TaskTypeToCassetteState(props.highlightTaskType);
    const showCassetteFPs = props.moduleTasks.filter(t => t.cassette)
        .filter(t => !checkedTasks.includes(t))
        .filter(t => !props.highlightTaskType || t.cassette!.cassetteState === highlightCassetteState)
        .map(t => `${t.cassette!.floor}:${t.cassette!.pos}`);
    const showDisableFPs = props.moduleTasks.filter(t => t.error)
        .filter(t => t.taskType === 'contact_us')
        .filter(t => !checkedTasks.includes(t))
        .filter(t => !props.highlightTaskType || props.highlightTaskType === 'contact_us')
        .map(t => `${t.error!.floor}:${t.error!.pos}`);
    const showErrorFPs = props.moduleTasks.filter(t => t.error)
        .filter(t => t.taskType === 'clean_position')
        .filter(t => !checkedTasks.includes(t))
        .filter(t => !props.highlightTaskType || props.highlightTaskType === 'clean_position')
        .map(t => `${t.error!.floor}:${t.error!.pos}`);
    const showTankTypes = props.moduleTasks.filter(t => t.tank)
        .filter(t => !checkedTasks.includes(t))
        .filter(t => !props.highlightTaskType || props.highlightTaskType === 'refill_tank')
        .map(t => t.tank?.tankType);

    const floorRange = Array.from({ length: 5 }, (_, i) => 5 - i);  // 5..1
    const posRange = Array.from({ length: 6 }, (_, i) => i + 1);    // 1..6

    return <View style={[sharedLayoutStyles.mobileContainer, styles.mainContent]}>
        {floorRange.map((f) => // For each floor
            <View style={styles.floorRow} key={f}>
                {posRange.map((p) => { // For each position
                    const key = `${f}:${p}`;
                    const hasTask = showCassetteFPs.includes(key);
                    const hasError = showErrorFPs.includes(key);
                    const isDisabled = showDisableFPs.includes(key);

                    const cassette = props.module.cassettesByFP[key];
                    const dimmed = ShouldDimCassette(cassette, props.highlightTaskType);

                    return <View style={styles.positionContainer} key={key}>
                        {cassette && <>
                            <View style={styles.positionSpacerRow} />
                            <View style={styles.positionLabelView}>
                                <ModuleCassetteLabel text={i18n.t('CROP_NAME_' + cassette?.cropCode)} dimmed={dimmed} />
                            </View>
                        </>}
                        <View style={styles.positionRGBRow}>
                            {isDisabled && !hasError &&
                                <ModulePositionDisabledIndicator />}
                            {hasError && !isDisabled &&
                                <ModulePositionErrorIndicator />}
                            {hasTask && !isDisabled && !hasError && !dimmed &&
                                <ModuleCassetteTaskIndicator />}
                        </View>
                    </View>;
                })}
            </View>
        )}
        <View style={styles.basementRow}>
            {!(props.highlightTaskType && props.highlightTaskType !== 'refill_tank') &&
                <>
                    <View style={[styles.tank, { backgroundColor: Colors[colorScheme].tankRed },
                    !showTankTypes.includes('RED') && { opacity: 0 }]} />
                    <View style={[styles.tank, { backgroundColor: Colors[colorScheme].tankBlue },
                    !showTankTypes.includes('BLUE') && { opacity: 0 }]} />
                    <View style={[styles.tank, { backgroundColor: Colors[colorScheme].tankYellow },
                    !showTankTypes.includes('YELLOW') && { opacity: 0 }]} />
                </>
            }
        </View>
    </View >;
}


const styles = StyleSheet.create({
    mainContent: {
        paddingTop: 16,
        paddingBottom: 16,
        paddingHorizontal: 20,

        // borderWidth: 1,
    },
    floorRow: {
        flexDirection: 'row',
    },
    positionContainer: {
        flex: 1,
        justifyContent: 'flex-end',
        height: 79,

        // borderWidth: 1,
        // borderColor: 'blue'
    },
    positionSpacerRow: {
    },
    positionLabelView: {
        width: 42,

        justifyContent: 'flex-end', // grow from bottom

        // borderWidth: 1,
        // borderColor: 'yellow'
    },
    positionRGBRow: {
        height: 8,
        alignItems: 'center',
        marginTop: 8,

        // borderWidth: 1,
        // borderColor: 'red'
    },
    basementRow: {
        flexDirection: 'row',
        justifyContent: 'flex-end', // right-align
        height: 32,

        marginTop: 2,
        paddingHorizontal: 4,
        paddingVertical: 6,

        // borderWidth: 1,
        // borderColor: 'blue'
    },
    tank: {
        width: 32,
        height: 32,

        borderRadius: 4,

        shadowOffset: {
            width: 0,       // X
            height: 4,      // Y
        },
        shadowRadius: 6,    // B
        shadowColor: '#000000',
        shadowOpacity: 0.3,

        marginHorizontal: 3,
    },
});
