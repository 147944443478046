import i18n from 'i18n-js';
import { Image, StyleSheet, Text } from 'react-native';

import images from '../assets/images';
import Error from '../components/Error';
import Loading from '../components/Loading';
import { MyFlatList } from '../components/MyComponents';
import TasksTypeCard from "../components/TasksTypeCard";
import { ThemedView } from '../components/Themed';
import Colors from '../constants/Colors';
import { styles as sharedLayoutStyles } from "../constants/Layout";
import { useData } from '../contexts/DataContext';
import useColorScheme from '../hooks/useColorScheme';
import { TasksOverviewScreenProps } from '../types';

/*
    The root screen of the Tasks stack.
    Shows a list of cards, grouped by task type (i.e. n task types)
*/
export default function TasksOverviewScreen(props: TasksOverviewScreenProps) {
    const colorScheme = useColorScheme();
    const { modules, isLoading, tasks, checkedTasks } = useData();

    if (modules) {
        const items = [];

        const harvestTaskCount = tasks.filter(t => t.cassette)
            .filter(t => t.taskType === 'harvest')
            .filter(t => t.module.online && !checkedTasks.includes(t)).length;
        if (harvestTaskCount > 0) {
            items.push({
                taskType: 'harvest',
                taskCount: harvestTaskCount
            });
        }

        const removeFilmTaskCount = tasks.filter(t => t.cassette)
            .filter(t => t.taskType === 'remove_film')
            .filter(t => t.module.online && !checkedTasks.includes(t)).length;
        if (removeFilmTaskCount > 0) {
            items.push({
                taskType: 'remove_film',
                taskCount: removeFilmTaskCount
            });
        }

        const refillTankTaskCount = tasks.filter(t => t.tank)
            .filter(t => t.module.online && !checkedTasks.includes(t)).length;
        if (refillTankTaskCount > 0) {
            items.push({
                taskType: 'refill_tank',
                taskCount: refillTankTaskCount
            });
        }

        const cleanPositionTaskCount = tasks.filter(t => t.error)
            .filter(t => t.taskType === 'clean_position')
            .filter(t => t.module.online && !checkedTasks.includes(t)).length;
        if (cleanPositionTaskCount > 0) {
            items.push({
                taskType: 'clean_position',
                taskCount: cleanPositionTaskCount
            });
        }

        const contactUsTaskCount = tasks.filter(t => t.error)
            .filter(t => t.taskType === 'contact_us')
            .filter(t => t.module.online && !checkedTasks.includes(t)).length;
        if (contactUsTaskCount > 0) {
            items.push({
                taskType: 'contact_us',
                taskCount: contactUsTaskCount
            });
        }

        if (items.length > 0) {
            return <MyFlatList style={sharedLayoutStyles.rootContainer}
                contentContainerStyle={sharedLayoutStyles.rootContentContainer}
                contentInsetAdjustmentBehavior="automatic"
                data={items}
                renderItem={({ item, index }) => <TasksTypeCard {...item} index={index} />}
                keyExtractor={item => item.taskType}
            />;
        }
        else {
            return <ThemedView style={[
                sharedLayoutStyles.rootContainer,
                sharedLayoutStyles.rootContentContainer,
            ]}>
                <ThemedView style={[sharedLayoutStyles.mobileContainer, styles.noTasksView]}>
                    <Image style={styles.noTasksImage} source={colorScheme === 'dark' ? images.task_harvest_white : images.task_harvest} />
                    <Text style={[{ color: Colors[colorScheme].grayText }, styles.noTasksText]}>{i18n.t('TASKSOVERVIEWSCREEN_NOTASKS_HEADER')}</Text>
                </ThemedView>
            </ThemedView>
        }
    }
    else {
        return isLoading ? <Loading /> : <Error />;
    }
}

const styles = StyleSheet.create({
    noTasksView: {
        alignItems: 'center',    // horizontal
    },
    noTasksImage: {
        width: 60,
        height: 60,
        marginTop: 120,
        marginBottom: 16,
    },
    noTasksText: {
        textAlign: 'center',
        fontSize: 20,
        fontWeight: '400',
    }
});
