import { Ionicons } from '@expo/vector-icons';
import { useTheme } from '@react-navigation/native';
import * as Analytics from 'expo-firebase-analytics';
import { Linking, Pressable, StyleSheet } from 'react-native';

import { ThemedText } from '../components/Themed';
import Colors from '../constants/Colors';
import useColorScheme from '../hooks/useColorScheme';


export default function TasksDetailFooterLink(props:
    { iconName: React.ComponentProps<typeof Ionicons>['name'], text: string, url: string }) {
    const colorScheme = useColorScheme();
    const { colors } = useTheme();

    function onPress() {
        Linking.openURL(props.url);

        Analytics.logEvent('open_url', {
            url: props.url,
        });
    }

    return <Pressable style={styles.footerLinkRow} onPress={onPress}>
        {({ pressed }) =>
            <>
                <Ionicons name={props.iconName} size={24} color={colors.text} style={[
                    styles.footerLinkIcon,
                    { color: pressed ? Colors[colorScheme].linkHighlighted : Colors[colorScheme].link }
                ]} />
                <ThemedText style={[
                    styles.footerLinkText,
                    { color: pressed ? Colors[colorScheme].linkHighlighted : Colors[colorScheme].link }
                ]}>{props.text}</ThemedText>
            </>
        }
    </Pressable>
}


const styles = StyleSheet.create({
    footerLinkRow: {
        flexDirection: 'row',
        alignItems: 'center', // vertically
        marginBottom: 8,
    },
    footerLinkIcon: {
        paddingRight: 8,
    },
    footerLinkText: {
        fontSize: 16,
        fontWeight: '500',
    },
});
