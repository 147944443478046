/*
    React Navigation + Expo AuthSession + Auth0
*/
// https://docs.expo.dev/guides/authentication/
// https://medium.com/@mike.almpertis/react-navigation-5-0-authentication-flow-using-context-hooks-and-aws-cognito-3f7d50df7921 (seemingly based on the previous)
// https://github.com/expo/examples/issues/209#issuecomment-1001197376
import AsyncStorage from '@react-native-async-storage/async-storage';
import * as AuthSession from "expo-auth-session";
import * as WebBrowser from 'expo-web-browser';
import jwtDecode, { JwtPayload } from "jwt-decode";
// import * as SecureStore from 'expo-secure-store';
import React from 'react';
import { Platform } from "react-native";

import { AuthContextType, AuthDispatchActionType } from '../types';


const SECURE_AUTH_TOKEN_KEY = "auth_token";

// https://docs.expo.dev/guides/authentication/
WebBrowser.maybeCompleteAuthSession();

// https://github.com/expo/examples/blob/master/with-auth0/App.js
const credentials = require("../auth0-configuration");
const auth0ClientId = credentials.clientId;
const authorizationEndpoint = `https://${credentials.domain}/authorize`;
const endSessionEndpoint = `https://${credentials.domain}/v2/logout`;

// https://docs.expo.dev/guides/authentication/#redirect-uri-patterns
const useProxy = Platform.select({ web: false, default: true });
const redirectUri = AuthSession.makeRedirectUri({ useProxy });


// https://kentcdodds.com/blog/how-to-use-react-context-effectively#typescript
const AuthContext = React.createContext<AuthContextType | undefined>(undefined);

type AuthProviderProps = React.PropsWithChildren<object>;

function AuthProvider({ children }: AuthProviderProps) {
    // based on https://reactnavigation.org/docs/auth-flow/#implement-the-logic-for-restoring-the-token
    // specifically https://reactnavigation.org/examples/6.x/auth-flow.js
    const [authState, dispatch] = React.useReducer(
        (prevState: any, action: AuthDispatchActionType) => {
            // console.debug(prevState, action);
            switch (action.type) {
                case 'RESTORE_TOKEN':
                    return {
                        ...prevState,
                        userToken: action.token,
                        userObject: action.token ? jwtDecode<JwtPayload>(action.token) : null,
                        isLoading: false,
                    };
                case 'SIGN_IN':
                    return {
                        ...prevState,
                        isSignout: false,
                        userToken: action.token,
                        userObject: action.token ? jwtDecode<JwtPayload>(action.token) : null,
                    };
                case 'SIGN_OUT':
                    return {
                        ...prevState,
                        isSignout: true,
                        userToken: null,
                    };
            }
        }, {
        isLoading: true,
        isSignout: false,
        userToken: null,
        userObject: null
    })

    // Create and load an auth request
    const [, result, promptAsync] = AuthSession.useAuthRequest(
        {
            redirectUri,
            clientId: auth0ClientId,
            // id_token will return a JWT token
            responseType: "id_token",
            // retrieve the user's profile
            scopes: ["openid", "profile"],
            extraParams: {
                // ideally, this will be a random value
                nonce: "nonce",
            },
        },
        {
            authorizationEndpoint
        }
    );

    React.useEffect(() => {
        // console.debug('AuthContext useEffect()');

        // based on https://reactnavigation.org/docs/auth-flow/#implement-the-logic-for-restoring-the-token
        // Fetch the token from storage then navigate to our appropriate place
        const bootstrapAsync = async () => {
            let token = null;

            try {
                token = await AsyncStorage.getItem(SECURE_AUTH_TOKEN_KEY)
                // token = await SecureStore.getItemAsync('userToken');
            } catch (e) {
                // Restoring token failed
                console.error(e);
            }

            // After restoring token, we may need to validate it in production apps
            // This will switch to the App screen or Auth screen and this loading
            // screen will be unmounted and thrown away.
            dispatch({ type: 'RESTORE_TOKEN', token });
        };

        bootstrapAsync();

        if (result) {
            // Retrieve the redirect URL, add this to the callback URL list
            // of your Auth0 application.
            if (result.type === "error") {
                console.error(result.params.error_description);
                return;
            }
            if (result.type === "success") {
                // Retrieve the JWT token
                const jwtToken = result.params.id_token;

                // https://docs.expo.dev/guides/authentication/#storing-data
                // SecureStore does not support web
                // https://github.com/expo/expo/issues/7744#issuecomment-613137229
                if (Platform.OS === 'web') {
                    AsyncStorage.setItem(SECURE_AUTH_TOKEN_KEY, jwtToken)
                }
                else {
                    // Securely store the auth on your device
                    // TODO: iOS and Android should store token using SecureStore instead of AsyncStorage
                    // SecureStore.setItemAsync(SECURE_AUTH_TOKEN_KEY, jwtToken);
                    AsyncStorage.setItem(SECURE_AUTH_TOKEN_KEY, jwtToken)
                }

                dispatch({ type: 'SIGN_IN', token: jwtToken });
            }
        }

        // https://reactjs.org/docs/hooks-effect.html#effects-with-cleanup
        return function cleanup() {
            // console.debug('AuthContext cleanup()');
        };
    }, [result, authState.userToken]);


    // based on https://reactnavigation.org/docs/auth-flow/#implement-the-logic-for-restoring-the-token
    async function signIn() {
        // console.debug("signIn()");

        // In a production app, we need to send some data (usually username, password) to server and get a token
        // We will also need to handle errors if sign in failed
        // After getting token, we need to persist the token using `SecureStore`

        await promptAsync({ useProxy });
    }

    // based on https://reactnavigation.org/docs/auth-flow/#implement-the-logic-for-restoring-the-token
    async function signOut() {
        // console.debug("signOut()");

        // https://auth0.com/docs/authenticate/login/logout
        /*
            Application Session Layer:
            Log users out of your applications by clearing their sessions
        */
        await AsyncStorage.removeItem(SECURE_AUTH_TOKEN_KEY);

        /*
            Auth0 Session Layer:
            Log users out of Auth0 by clearing the Single Sign-on (SSO) cookie
        */
        // "One challenge with this, Expo’s AuthSession proxy website (see their
        // docs for more) doesn’t correctly handle logout redirects back to the app"
        // https://jamesirish.io/blog/auth0-pkce-flow-using-expo-authsession

        // See also https://github.com/expo/auth0-example/issues/25#issuecomment-468582410
        const redirectUrl = AuthSession.makeRedirectUri({ useProxy: false });
        const logoutUrl = `${endSessionEndpoint}?client_id=${auth0ClientId}&returnTo=${redirectUrl}`;

        try {
            if (Platform.OS === 'web') {
                /*
                    Due to https://github.com/expo/expo/issues/10459
                    (reopened as https://github.com/expo/expo/issues/16902)
                    WebBrowser.openAuthSessionAsync() results in the exception 
                    "Error: Popup window was blocked by the browser or failed to
                    open. This can happen in mobile browsers when the window.open()
                    method was invoked too long after a user input was fired."

                    When this happens, retrying with WebBrowser.openBrowserAsync()
                    has no effect, and cleaning up via WebBrowser.dismissAuthSession()
                    doesn't help either.
                    
                    Workaround by checking the user agent for Safari for now.
                */
                const userAgent = window.navigator.userAgent;
                if (userAgent.indexOf('AppleWebKit') !== -1 && userAgent.indexOf('Chrome') === -1) {
                    console.warn('Safari detected. Using workaround for Auth0 logout.');
                    await WebBrowser.openBrowserAsync(logoutUrl); // open new tab
                    window.close(); // close new tab
                }
                else {
                    await WebBrowser.openAuthSessionAsync(logoutUrl, redirectUrl);
                }
            }
        }
        catch (err) {
            console.error(err);
        }

        dispatch({ type: 'SIGN_OUT' });
    };

    // https://kentcdodds.com/blog/how-to-use-react-context-effectively#typescript
    // NOTE: you *might* need to memoize this value
    // Learn more in http://kcd.im/optimize-context
    const value = { signIn, signOut, authState }
    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    )
}

// https://kentcdodds.com/blog/how-to-use-react-context-effectively#typescript
function useAuth() {
    const context = React.useContext(AuthContext)
    if (context === undefined) {
        throw new Error('useAuth must be used within a AuthProvider')
    }
    return context
}

export { AuthProvider, useAuth }
