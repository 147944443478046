import * as Localization from 'expo-localization';
import { StatusBar } from 'expo-status-bar';
import i18n from 'i18n-js';
import { SafeAreaProvider } from 'react-native-safe-area-context';

import translations from './assets/strings/translations.json';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import useCachedResources from './hooks/useCachedResources';
import useColorScheme from './hooks/useColorScheme';
import Navigation from './navigation';

// Set the locale once at the beginning of your app.
i18n.locale = Localization.locale;
i18n.fallbacks = true;
i18n.translations = translations;


function Main() {
  const isLoadingComplete = useCachedResources();
  const colorScheme = useColorScheme();
  const { authState } = useAuth();

  if (!isLoadingComplete || authState.isLoading) {
    return null;
  } else {
    return (
      <SafeAreaProvider>
        <Navigation colorScheme={colorScheme} />
        <StatusBar />
      </SafeAreaProvider>
    );
  }
}

export default function App() {
  return <AuthProvider>
    <Main />
  </AuthProvider>;
}
