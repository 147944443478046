import { StyleSheet, View } from 'react-native';

import Colors from '../constants/Colors';
import useColorScheme from '../hooks/useColorScheme';


export default function ModuleCassetteTaskIndicator(props: { color?: string }) {
    const colorScheme = useColorScheme();

    return <View style={[
        styles.component,
        { backgroundColor: props.color ?? Colors[colorScheme].taskIndicatorOverlay }
    ]} />;
}

const styles = StyleSheet.create({
    component: {
        width: 8,
        height: 8,

        borderRadius: 50,
        marginHorizontal: 2,
    },
});
