// https://docs.expo.dev/ui-programming/implementing-a-checkbox/
import { Ionicons } from '@expo/vector-icons';
import * as Analytics from 'expo-firebase-analytics';
import React, { useState } from 'react';
import { StyleSheet, View } from 'react-native';

import Colors from '../constants/Colors';
import { useAuth } from '../contexts/AuthContext';
import { useData } from '../contexts/DataContext';
import useColorScheme from '../hooks/useColorScheme';
import { Task } from "../types";
import { MyPressable } from './MyComponents';


export default function TaskCheckbox(props: React.PropsWithChildren<{ task: Task }>) {
    const { authState } = useAuth();
    const colorScheme = useColorScheme();
    const { checkedTasks, performRemoveFilmCassetteTask, performHarvestCassetteTask, performRefillTankTask } = useData();
    const [isChecked, setIsChecked] = useState(checkedTasks.includes(props.task));

    // writing as const function to workaround https://github.com/microsoft/TypeScript/issues/36193#issuecomment-827952456
    const toggleTaskCheckbox = async function () {
        // Inger and I decided that the checkbox can't be unchecked, since
        // `remove_film` is a one-way command, and we want to keep the behavior
        // consistent across tasks
        setIsChecked(true);

        if (authState?.userToken) {
            switch (props.task.taskType) {
                case 'remove_film':
                    await performRemoveFilmCassetteTask(authState?.userToken, props.task);
                    break;
                case 'harvest':
                    await performHarvestCassetteTask(authState?.userToken, props.task);
                    break;
                case 'refill_tank':
                    await performRefillTankTask(authState?.userToken, props.task);
                    break;
            }
        }

        Analytics.logEvent('toggle_task_checkbox', {
            moduleId: props.task.module.moduleId,
            nickname: authState.userObject.nickname,
            taskType: props.task.taskType,
        });
    };

    return (
        <MyPressable style={styles.componentContainer}
            onPress={async () => await toggleTaskCheckbox()}
        >
            {({ pressed }) =>
                <>
                    {isChecked
                        ?
                        <Ionicons
                            style={styles.checkboxOn}
                            name="checkmark-circle"
                            // The circle should appear 25x25 but there is padding in the icon
                            size={32}
                            color={pressed ? Colors[colorScheme].taskCheckmarkHighlighted : Colors[colorScheme].taskCheckmarkOn}
                        />
                        :
                        <View style={[
                            styles.checkboxOff,
                            { borderColor: Colors[colorScheme].taskCheckmarkOff },
                            pressed && { backgroundColor: Colors[colorScheme].taskCheckmarkHighlighted }
                        ]} />
                    }

                    <View style={styles.childrenContainer}>
                        {props.children}
                    </View>
                </>
            }
        </MyPressable >
    );
}

// Buttons have five states that define their appearance: default, highlighted, focused, selected, and disabled.
const styles = StyleSheet.create({
    componentContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        height: 36,
    },
    childrenContainer: {
        marginLeft: 8
    },
    checkboxOff: {
        width: 25,
        height: 25,
        borderRadius: 50,
        borderWidth: 2,

        marginRight: 3,
    },
    checkboxOn: {
        marginLeft: -4,

        // borderWidth: 1,
    },
});
