/**
 * Learn more about using TypeScript with React Navigation:
 * https://reactnavigation.org/docs/typescript/
 */

import { Ionicons } from '@expo/vector-icons';
import { BottomTabScreenProps } from '@react-navigation/bottom-tabs';
import { CompositeScreenProps, NavigatorScreenParams } from '@react-navigation/native';
import { NativeStackScreenProps } from '@react-navigation/native-stack';


/* Navigation */

// https://reactnavigation.org/docs/typescript#specifying-default-types-for-usenavigation-link-ref-etc
declare global {
  namespace ReactNavigation {
    interface RootParamList extends RootStackParamList { }
  }
}

// *ParamList: https://reactnavigation.org/docs/typescript#type-checking-the-navigator
// *ScreenProps: https://reactnavigation.org/docs/typescript#type-checking-screens

export type RootStackParamList = {
  // signed out
  SignIn: undefined;
  // signed in
  Root: NavigatorScreenParams<RootTabParamList> | undefined;
  NotFound: undefined;
};

export type RootStackScreenProps<Screen extends keyof RootStackParamList> = NativeStackScreenProps<
  RootStackParamList,
  Screen
>;

export type RootTabParamList = {
  ModulesTab: NavigatorScreenParams<ModulesStackParamList>;
  TasksTab: NavigatorScreenParams<TasksStackParamList>;
  SupportTab: undefined;
  AccountTab: undefined;
};

export type RootTabScreenProps<Screen extends keyof RootTabParamList> = CompositeScreenProps<
  BottomTabScreenProps<RootTabParamList, Screen>,
  NativeStackScreenProps<RootStackParamList>
>;

// screens/ModulesStackScreen.tsx

export type ModulesStackParamList = {
  ModulesList: undefined;
  ModulesDetail: { moduleId: string };
};

// screens/ModulesListScreen.tsx

export type ModulesListScreenProps = CompositeScreenProps<
  NativeStackScreenProps<ModulesStackParamList, 'ModulesList'>,
  RootTabScreenProps<'ModulesTab'>
>;

// screens/ModulesDetailScreen.tsx

export type ModulesDetailScreenProps = CompositeScreenProps<
  NativeStackScreenProps<ModulesStackParamList, 'ModulesDetail'>,
  RootTabScreenProps<'ModulesTab'>
>;

// screens/TasksStackScreen.tsx

export type TasksStackParamList = {
  TasksOverview: undefined;
  TasksModuleList: { taskType: TaskType };
  TasksDetail: { taskType: TaskType, moduleId: string };
};

export type TasksOverviewScreenProps = CompositeScreenProps<
  NativeStackScreenProps<TasksStackParamList, 'TasksOverview'>,
  RootTabScreenProps<'TasksTab'>
>;

// screens/TasksModuleListScreen.tsx

export type TasksModuleListScreenProps = CompositeScreenProps<
  NativeStackScreenProps<TasksStackParamList, 'TasksModuleList'>,
  RootTabScreenProps<'TasksTab'>
>;

// screens/TasksDetailScreen.tsx

export type TasksDetailScreenProps = CompositeScreenProps<
  NativeStackScreenProps<TasksStackParamList, 'TasksDetail'>,
  RootTabScreenProps<'TasksTab'>
>;

// screens/SupportStackScreen.tsx

export type SupportStackParamList = {
  SupportIndex: undefined;
  SupportDetail: { title: string, url: string };
  SupportInstructionManual: undefined;
};

export type SupportIndexScreenProps = CompositeScreenProps<
  NativeStackScreenProps<SupportStackParamList, 'SupportIndex'>,
  RootTabScreenProps<'SupportTab'>
>;

export type SupportDetailScreenProps = CompositeScreenProps<
  NativeStackScreenProps<SupportStackParamList, 'SupportDetail'>,
  RootTabScreenProps<'SupportTab'>
>;


/* Contexts */

// contexts/AuthContext.tsx

export type AuthContextType = {
  signIn: () => void;
  signOut: () => void;
  authState: {
    isLoading: boolean;
    isSignout: boolean;
    userToken?: string;
    userObject?: any;
  };
}

// https://github.com/typescript-cheatsheets/react/blob/main/README.md#usereducer
export type AuthDispatchActionType =
  | { type: 'RESTORE_TOKEN', token: string | null }
  | { type: 'SIGN_IN', token: string }
  | { type: 'SIGN_OUT' }

// contexts/DataContext.tsx

export enum GrowLightsValue {
  Off = "OFF",
  On = "ON",
  Dim = "DIM"
}

export type DataContextType = {
  crops?: Crop[] | null;
  modules?: Module[] | null;
  sites?: Site[] | null;
  isLoading: boolean;
  tasks: Task[];
  checkedTasks: Task[];
  performUnlockDoors: (authToken: string, moduleId: string) => void;
  performSetGrowLights: (authToken: string, module: Module, value: GrowLightsValue) => void;
  performSetWateringEnabled: (authToken: string, module: Module, value: boolean) => void;
  performRemoveFilmCassetteTask: (authToken: string, task: Task) => void;
  performHarvestCassetteTask: (authToken: string, task: Task) => void;
  performRefillTankTask: (authToken: string, task: Task) => void;
}


/* Components */

// components/ListItemProps.tsx

export type ListItemProps = {
  iconName: keyof typeof Ionicons.glyphMap;
  title: string;
  onPress: CallableFunction;
};

// components/ModuleOverlay.tsx, components/ModuleMini.tsx

export type ModuleRenderedProps = {
  module: Module;
  moduleTasks: Task[];
  highlightTaskType?: TaskType;
};


/* Data Model */

export interface Module {
  // from 'modules' table
  moduleId: string;
  moduleName: string;
  siteName: string;
  // from 'app_metrics_lastpoint' table
  online: boolean;
  version: string;
  growlightsOn: boolean;
  growlightsIntensity: number;
  wateringEnabled: boolean;
  blueadditivetankBottomWet: boolean;
  redadditivetankBottomWet: boolean;
  phadjustertankBottomWet: boolean;
  // from 'cassettes' table
  cassettes?: Cassette[];
  errors: PosError[];
  // computed
  cassettesByFP: Record<string, Cassette>;
  tanks: Record<TankType, Tank>;
  fullName: string;
};

export interface Cassette {
  moduleId: string;
  floor: number;
  pos: number;
  cassetteId: string;
  cropCode: string;
  cassetteState: string;
};

export interface PosError {
  errorCode: number;
  floor: number;
  pos: number;
  moduleId: string;
}

export type TankType = 'RED' | 'BLUE' | 'YELLOW';

export interface Tank {
  tankType: TankType;
  isDry: boolean;
};

// These are even exposed to the user in the URL route
export type TaskType = 'harvest' | 'remove_film' | 'refill_tank' | 'clean_position' | 'contact_us';

export interface Task {
  module: Module;
  taskType: TaskType;
  cassette?: Cassette;
  tank?: Tank;
  error?: PosError
  description: string; // e.g. "1:5 Basil" or "Red Tank"
};

export type CassetteState = 'GERMINATING' | 'REMOVE_FILM' | 'GROWING' | 'READY_FOR_HARVEST';

export function TaskTypeToCassetteState(taskType: TaskType): CassetteState | null {
  switch (taskType) {
    case 'remove_film':
      return 'REMOVE_FILM';
    case 'harvest':
      return 'READY_FOR_HARVEST';
    default:
      return null;
  }
}

export interface Crop {
  cropCode: string;
  names: Record<string, string>; // k=langCode, v=displayName
};

export interface Site {
  siteId: string;
  siteName: string;
  productionPlanHtml: string;
};
