import * as Analytics from 'expo-firebase-analytics';
import i18n from 'i18n-js';
import { StyleSheet, Text, View } from 'react-native';

import ListItem from '../components/ListItem';
import { MyFlatList } from '../components/MyComponents';
import Colors from '../constants/Colors';
import { styles as sharedLayoutStyles } from "../constants/Layout";
import { useAuth } from '../contexts/AuthContext';
import useColorScheme from '../hooks/useColorScheme';
import { version } from '../package.json';
import { RootTabScreenProps } from '../types';


const FLATLIST_DATA = [
  {
    titleKey: 'ACCOUNTSCREEN_SIGNOUT_LISTITEM',
  },
];


export default function AccountScreen({ route, navigation }: RootTabScreenProps<'AccountTab'>) {
  const colorScheme = useColorScheme();
  const { signOut, authState } = useAuth();

  const versionDescriptionFormat = i18n.t('ACCOUNTSCREEN_VERSIONDESCRIPTION_FORMAT');
  const versionDescription = versionDescriptionFormat
    .replace('$1', version)
    .replace('$2', process.env.BUILD_COMMIT_ID?.substring(0, 7) || '--');

  const currentAccountDescriptionFormat = i18n.t('ACCOUNTSCREEN_CURRENTACCOUNT_FORMAT');
  const currentAccountDescription = currentAccountDescriptionFormat
    .replace('$1', authState.userObject.name)
    .replace('$2', authState.userObject.nickname);

  function onPress() {
    signOut();

    Analytics.logEvent('sign_out', {
      screen: 'AccountScreen'
    });
  }

  return (
    <MyFlatList style={sharedLayoutStyles.rootContainer}
      contentContainerStyle={sharedLayoutStyles.rootContentContainer}
      data={FLATLIST_DATA}
      renderItem={({ item }) =>
        <ListItem iconName="exit-outline" title={i18n.t(item.titleKey)} onPress={onPress} />
      }
      keyExtractor={item => item.titleKey}
      ListFooterComponent={<View style={[sharedLayoutStyles.mobileContainer, styles.footerView]}>
        <Text style={{ color: Colors[colorScheme].grayText }}>{currentAccountDescription}</Text>
        <Text style={{ color: Colors[colorScheme].grayText }}>{versionDescription}</Text>
      </View>}
    />
  );
}


const styles = StyleSheet.create({
  footerView: {
    paddingVertical: 16,

    // borderWidth: 1,
    // borderColor: 'red'
  },
});
