/*
    Workaround behaviors/bugs in React Native components
*/

import { useScrollToTop } from '@react-navigation/native';
import * as React from 'react';
import { CSSProperties } from 'react';
import { FlatList, Platform, Pressable, PressableProps, View, ViewStyle } from 'react-native';


export function MyFlatList(props: FlatList['props']) {
    // Implement scroll-to-top
    // https://reactnavigation.org/docs/use-scroll-to-top/
    const ref = React.useRef(null);
    useScrollToTop(ref);
    return <FlatList ref={ref} {...props} />;
}

export function MyPressable(props: PressableProps) {
    // Disable tap-and-hold text selection on web
    const disableWebTextSelectionStyle: CSSProperties = Platform.OS === 'web' ? { WebkitUserSelect: "none" } : {};
    return <View style={disableWebTextSelectionStyle as ViewStyle}>
        <Pressable {...props} />
    </View>;
}
