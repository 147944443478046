import { Ionicons } from '@expo/vector-icons';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import * as Analytics from 'expo-firebase-analytics';
import * as WebBrowser from 'expo-web-browser';
import i18n from 'i18n-js';
import { Platform, View } from 'react-native';
// This is due to our use of react-native-web-webview rather than react-native-webview
// @ts-ignore
import { WebView } from 'react-native-webview';

import ListItem from '../components/ListItem';
import { MyFlatList } from '../components/MyComponents';
import { styles as sharedLayoutStyles } from "../constants/Layout";
import { useData } from '../contexts/DataContext';
import { RootTabScreenProps, SupportDetailScreenProps, SupportIndexScreenProps, SupportStackParamList } from '../types';


const FLATLIST_DATA = [
  {
    titleKey: 'SUPPORTINDEXSCREEN_VIEWINSTRUCTIONMANUAL_LISTITEM',
    external: false,
    /*
      In iOS PWA, a local source e.g. require('../assets/docs/Instruktionsmanual.pdf')
      gets loaded without chrome. The best workaround is to use an external domain.
      https://stackoverflow.com/questions/59337792/ios-pwa-standalone-how-to-force-opening-in-a-new-window
    */
    localizableUrlKey: 'SUPPORTINDEXSCREEN_VIEWINSTRUCTIONMANUAL_URL',
    iconFamily: 'Ionicons',
    iconName: 'book-outline',
  },
  {
    titleKey: 'SUPPORTINDEXSCREEN_VIEWTROUBLESHOOTINGGUIDE_LISTITEM',
    external: false,
    localizableUrlKey: 'SUPPORTINDEXSCREEN_VIEWTROUBLESHOOTINGGUIDE_URL',
    iconFamily: 'Ionicons',
    iconName: 'book-outline',
  },
  {
    titleKey: 'SUPPORTINDEXSCREEN_REPORTISSUE_LISTITEM',
    external: true,
    url: 'https://gronska.atlassian.net/servicedesk/customer/portal/1/group/1/create/6',
    iconFamily: 'Ionicons',
    iconName: 'alert-circle-outline',
  },
  {
    titleKey: 'SUPPORTINDEXSCREEN_SUBMITIDEAS_LISTITEM',
    external: true,
    url: 'https://gronska.atlassian.net/servicedesk/customer/portal/1/group/1/create/8',
    iconFamily: 'Ionicons',
    iconName: 'bulb-outline',
  },
  {
    titleKey: 'SUPPORTINDEXSCREEN_CONTACTUS_LISTITEM',
    external: false,
    localizableUrlKey: 'SUPPORTINDEXSCREEN_CONTACTUS_URL',
    iconFamily: 'Ionicons',
    iconName: 'call-outline',
  }
];


function SupportIndexScreen({ route, navigation }: SupportIndexScreenProps) {
  const { sites } = useData();

  function onPress(item: {
    titleKey: string,
    external: boolean,
    url: string,
    localizableUrlKey?: string,
    iconFamily: string,
    iconName: string,
  }) {
    const url = item.localizableUrlKey ? i18n.t(item.localizableUrlKey) : item.url;
    console.log(`Opening ${url}`);

    // Jira CS has same-origin policy set, so it won't load in iframe
    // "Refused to display 'https://gronska.atlassian.net/' in a frame because it set 'X-Frame-Options' to 'sameorigin'."
    if (item.external) {
      WebBrowser.openBrowserAsync(url);
    }
    else {
      navigation.navigate('SupportDetail', { title: i18n.t(item.titleKey), url })
    }

    Analytics.logEvent('open_url', {
      screen: 'SupportIndexScreen',
      url,
    });
  }

  // [GOSW-568] Add a static page with production plan info in app
  const data = [...FLATLIST_DATA]; // copy()
  if (sites) {
    let html = '';
    for (const site of sites) {
      if (site.productionPlanHtml !== null) {
        html += `<h1>${site.siteName}</h1>\n${site.productionPlanHtml}\n`;
      }
    }

    if (html.length > 0) {
      const cssStyle = 'body { font-family: system-ui, sans-serif; } @media (prefers-color-scheme: dark) { body { color: white; } }';
      data.unshift(
        {
          titleKey: 'SUPPORTINDEXSCREEN_VIEWPRODUCTIONPLAN_LISTITEM',
          external: false,
          url: `data:text/html;charset=utf-8,<style>${cssStyle}</style>${html}`,
          iconFamily: 'Ionicons',
          iconName: 'clipboard-outline',
        }
      );
    }
  }

  return <MyFlatList style={sharedLayoutStyles.rootContainer}
    contentContainerStyle={[
      sharedLayoutStyles.rootContentContainer,
      sharedLayoutStyles.cardListView
    ]}
    data={data}
    renderItem={({ item }) =>
      <ListItem iconName={item.iconName as keyof typeof Ionicons.glyphMap} title={i18n.t(item.titleKey)} onPress={() => onPress(item)} />
    }
    keyExtractor={item => item.titleKey} />;
}

function SupportDetailScreen({ route, navigation }: SupportDetailScreenProps) {
  // https://github.com/facebook/react-native/issues/16133#issuecomment-333195078
  let source = { uri: route.params.url };
  if (Platform.OS === 'ios') {
    // @ts-ignore
    source = route.params.url;
  }

  // Edge for Mac shows "no enabled plugin supports this MIME type"
  // when PDF is loaded in mobile mode but not desktop mode

  return (
    <View style={[sharedLayoutStyles.rootContainer, sharedLayoutStyles.rootContentContainer]}>
      <WebView source={source} scalesPageToFit />
    </View>
  );
}


export default function SupportStackScreen({ navigation }: RootTabScreenProps<'SupportTab'>) {
  const SupportStack = createNativeStackNavigator<SupportStackParamList>();

  return (
    <SupportStack.Navigator
      screenOptions={{
        headerShadowVisible: false, // hide divider line
      }}>
      <SupportStack.Screen name="SupportIndex" component={SupportIndexScreen}
        options={{
          title: i18n.t('SUPPORTINDEXSCREEN_TITLE'),
        }}
      />
      <SupportStack.Screen name="SupportDetail" component={SupportDetailScreen}
        // https://reactnavigation.org/docs/headers/#using-params-in-the-title
        options={({ route }) => ({ title: route.params.title })}
      />
    </SupportStack.Navigator>
  );
}
