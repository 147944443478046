// const tintColorLight = '#000';
// const tintColorDark = '#fff';

export const Brand = {
  // blue: '#1C32FF',
  green: '#7FE200'
};

/*
  Usage:
    import useTheme from '../hooks/useTheme';
    
    const { colors } = useTheme();
    
        <Pressable style={({ pressed }) => { return { backgroundColor: pressed ? colors.actionHighlighted : colors.action } }}>
            {({ pressed }) => <Text style={{ color: pressed ? colors.actionTitleHighlighted : colors.actionTitle }}>I'm pressable!</Text>}
        </Pressable>

  @react-navigation/native defines a Theme with default values:
    Light: {"primary":"rgb(0, 122, 255)","background":"rgb(242, 242, 242)","card":"rgb(255, 255, 255)","text":"rgb(28, 28, 30)","border":"rgb(216, 216, 216)","notification":"rgb(255, 59, 48)"}
    Dark: {"primary":"rgb(10, 132, 255)","background":"rgb(1, 1, 1)","card":"rgb(18, 18, 18)","text":"rgb(229, 229, 231)","border":"rgb(39, 39, 41)","notification":"rgb(255, 69, 58)"}

  The following values are loaded by Navigation() in navigation/index.tsx and override the above.
*/
export default {
  light: {
    /* React Navigation theme overrides */
    primary: '#252525',
    background: '#F8F8F8',
    border: '#E0E0E0',

    /* Custom colors */
    grayText: '#767676',
    disabledText: '#999999',  // wifi-off

    // "Sign in", "Unlock", "Done" buttons
    action: '#1C32FF',
    actionHighlighted: '#1A2696',
    actionDisabled: '#E0E0E0',
    actionTitle: '#fff',
    actionTitleHighlighted: '#999999',
    actionTitleDisabled: '#F2F2F2',

    // "Unlock doors" task list item
    actionLink: '#1C32FF',
    actionLinkHighlighted: '#999999',
    actionLinkDisabled: '#B4B4B4',

    task: Brand.green,
    taskIndicatorText: '#7FE200',
    taskIndicatorOverlay: '#C7FF80',
    taskCheckmarkOff: '#252525', // 2 px border
    taskCheckmarkOn: Brand.green,
    taskCheckmarkHighlighted: '#E4E5E4',

    errorIndicatorOverlay: '#FF5050',

    link: '#252525',
    linkHighlighted: '#999999',

    calloutBackground: '#FCE6AE',

    tankRed: '#E34C2D',
    tankBlue: '#547AFF',
    tankYellow: '#FFCB45',
  },
  dark: {
    /* React Navigation theme overrides */
    primary: '#fff',
    card: '#1C1C1E',

    /* Custom colors */
    grayText: '#767676',
    disabledText: '#999999',  // wifi-off

    // "Sign in", "Unlock", "Done" buttons
    action: '#1C32FF',
    actionHighlighted: '#1A2696',
    actionDisabled: '#3C3C3F',  // overridden
    actionTitle: '#fff',
    actionTitleHighlighted: '#999999',
    actionTitleDisabled: '#656568',  // overridden

    // "Unlock doors" task list item
    actionLink: '#fff',
    actionLinkHighlighted: '#999999',
    actionLinkDisabled: '#B4B4B4',

    task: Brand.green,
    taskIndicatorText: '#7FE200',
    taskIndicatorOverlay: '#C7FF80',
    taskCheckmarkOff: '#fff', // 2 px border, overriden
    taskCheckmarkOn: Brand.green,
    taskCheckmarkHighlighted: '#E4E5E4',

    errorIndicatorOverlay: '#FF5050',

    link: '#fff',  // overridden
    linkHighlighted: '#999999',

    calloutBackground: '#FCE6AE',

    tankRed: '#E34C2D',
    tankBlue: '#547AFF',
    tankYellow: '#FFCB45',
  },
};
