import { Image, StyleSheet } from 'react-native';

import images from '../assets/images';
import { ThemedView } from '../components/Themed';
import { styles as sharedLayoutStyles } from "../constants/Layout";
import useColorScheme from '../hooks/useColorScheme';


export default function Loading() {
    const colorScheme = useColorScheme();

    return <ThemedView style={[
        sharedLayoutStyles.rootContainer,
        sharedLayoutStyles.rootContentContainer,
        styles.loadingView
    ]}>
        <Image source={colorScheme === 'dark' ? images.loading_dark : images.loading}
            style={{ width: 60, height: 94 }} />
    </ThemedView>;
}

export const styles = StyleSheet.create({
    loadingView: {
        justifyContent: 'center', // vertically
    },
});
