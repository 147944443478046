import { ImageBackground, StyleSheet, View } from 'react-native';

import images from '../assets/images';
import Colors, { Brand } from '../constants/Colors';
import { useData } from '../contexts/DataContext';
import useColorScheme from '../hooks/useColorScheme';
import { ModuleRenderedProps, TaskTypeToCassetteState } from '../types';
import ModulePositionDisabledIndicator from './ModulePositionDisabledIndicator';
import ModulePositionErrorIndicator from './ModulePositionErrorIndicator';


export default function ModuleMini(props: ModuleRenderedProps) {
    const colorScheme = useColorScheme();
    const { checkedTasks } = useData();

    const highlightCassetteState = props.highlightTaskType && TaskTypeToCassetteState(props.highlightTaskType);
    const showCassetteFPs = props.moduleTasks.filter(t => t.cassette)
        .filter(t => !checkedTasks.includes(t))
        .filter(t => !props.highlightTaskType || t.cassette!.cassetteState === highlightCassetteState)
        .map(t => `${t.cassette!.floor}:${t.cassette!.pos}`);
    const showDisableFPs = props.moduleTasks.filter(t => t.error)
        .filter(t => t.taskType === 'contact_us')
        .filter(t => !checkedTasks.includes(t))
        .filter(t => !props.highlightTaskType || props.highlightTaskType === 'contact_us')
        .map(t => `${t.error!.floor}:${t.error!.pos}`);
    const showErrorFPs = props.moduleTasks.filter(t => t.error)
        .filter(t => t.taskType === 'clean_position')
        .filter(t => !checkedTasks.includes(t))
        .filter(t => !props.highlightTaskType || props.highlightTaskType === 'clean_position')
        .map(t => `${t.error!.floor}:${t.error!.pos}`);
    const showTankTypes = props.moduleTasks.filter(t => t.tank)
        .filter(t => !checkedTasks.includes(t))
        .filter(t => !props.highlightTaskType || props.highlightTaskType === 'refill_tank')
        .map(t => t.tank?.tankType);

    const floorRange = Array.from({ length: 5 }, (_, i) => 5 - i);  // 5..1
    const posRange = Array.from({ length: 6 }, (_, i) => i + 1);    // 1..6

    return <ImageBackground style={[styles.imageBackground,
    !props.module.online && styles.imageBackgroundOffline]}
        source={images.module_rendered_mini}>
        <View style={styles.floorsView}>
            {floorRange.map((f) => // For each floor
                <View style={styles.floorRow} key={f}>
                    {posRange.map((p) => { // For each position
                        const key = `${f}:${p}`;
                        const hasTask = showCassetteFPs.includes(key);
                        const hasError = showErrorFPs.includes(key);
                        const isDisabled = showDisableFPs.includes(key);

                        return <View key={key} style={styles.floorPositionContainer}>
                            {isDisabled && !hasError &&
                                <ModulePositionDisabledIndicator />}
                            {hasError && !isDisabled &&
                                <ModulePositionErrorIndicator />}
                            {hasTask &&
                                <View style={styles.cassetteIndicatorDot} />
                            }
                        </View>;
                    })}
                </View>
            )
            }
        </View>
        <View style={styles.basementRow}>
            {!(props.highlightTaskType && props.highlightTaskType !== 'refill_tank') &&
                <>
                    <View style={[styles.tank, { backgroundColor: Colors[colorScheme].tankRed },
                    !showTankTypes.includes('RED') && { opacity: 0 }]} />
                    <View style={[styles.tank, { backgroundColor: Colors[colorScheme].tankBlue },
                    !showTankTypes.includes('BLUE') && { opacity: 0 }]} />
                    <View style={[styles.tank, { backgroundColor: Colors[colorScheme].tankYellow },
                    !showTankTypes.includes('YELLOW') && { opacity: 0 }]} />
                </>
            }
        </View>
    </ImageBackground>
}

const styles = StyleSheet.create({
    imageBackground: {
        width: 115,
        height: 185,
        alignItems: 'center',

        // borderWidth: 1,
        // borderColor: 'blue'
    },
    imageBackgroundOffline: {
        opacity: 0.333
    },
    floorsView: {
        paddingTop: 2,
        width: 102,

        // borderWidth: 1,
        // borderColor: 'green',
    },
    floorRow: {
        flexDirection: 'row',
        justifyContent: 'space-between',

        height: 25,
        marginBottom: 3,

        // borderWidth: 1,
        // borderColor: 'yellow',
    },
    floorPositionContainer: {
        flex: 1,
        alignItems: 'center', // horizontally
        justifyContent: 'flex-end', // bottom
        width: 64,
        height: 20,

        // borderWidth: 1,
        // borderColor: 'red',
    },
    cassetteIndicatorDot: {
        width: 8,
        height: 8,
        backgroundColor: Brand.green,
        borderRadius: 50, // circle

        shadowOffset: {
            width: 0,       // X
            height: 4,      // Y
        },
        shadowRadius: 6,    // B
        shadowColor: '#000000',
        shadowOpacity: 0.3,

        marginHorizontal: 2, // from bottom
    },
    basementRow: {
        flexDirection: 'row',
        justifyContent: 'flex-end', // right-align
        width: 102,

        marginTop: 4,
        marginRight: 4,

        // borderWidth: 1,
        // borderColor: 'blue'
    },
    tank: {
        width: 12,
        height: 12,

        borderRadius: 1,

        shadowOffset: {
            width: 0,       // X
            height: 4,      // Y
        },
        shadowRadius: 6,    // B
        shadowColor: '#000000',
        shadowOpacity: 0.3,

        marginHorizontal: 1,
    },
});
