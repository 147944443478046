import { StyleSheet, Text } from 'react-native';


export default function ModuleCassetteLabel(props: { text: string, dimmed?: boolean }) {
    return <Text style={[
        styles.cassetteLabel,
        props.dimmed ? styles.cassetteLabelDimmed : styles.cassetteLabelNormal,
    ]}
    /*
    ellipsizeMode='clip' doesn't work!
    https://github.com/necolas/react-native-web/issues/1336
    Workaround: style = {height: 12, overflow: 'hidden'}
    */
    // numberOfLines={1} ellipsizeMode='clip'
    >
        {props.text}
    </Text>
}

const styles = StyleSheet.create({
    cassetteLabel: {
        borderRadius: 3,
        paddingLeft: 1,

        // Workaround non-working ellipsizeMode='clip' above
        height: 12,
        overflow: 'hidden',

        shadowOffset: {
            width: 0,       // X
            height: 4,      // Y
        },
        shadowRadius: 6,    // B
        shadowColor: '#000000',
        shadowOpacity: 0.3,

        color: 'white',
        fontSize: 10,
        fontWeight: '500',

        // borderWidth: 1,
        // borderColor: 'blue'
    },
    cassetteLabelNormal: {
        backgroundColor: '#004F07',
        opacity: 0.85, // RN doesn't support 'mix-blend-mode: multiply'
    },
    cassetteLabelDimmed: {
        backgroundColor: '#7E7E7E',
        opacity: 0.85, // RN doesn't support 'mix-blend-mode: multiply'
    },
});