import { Ionicons } from '@expo/vector-icons';
import * as Font from 'expo-font';
import * as SplashScreen from 'expo-splash-screen';
import { useEffect, useState } from 'react';
import { Image } from 'react-native';

import images, { imageNamesToPreload } from '../assets/images';


export default function useCachedResources() {
  const [isLoadingComplete, setLoadingComplete] = useState(false);

  // Load any resources or data that we need prior to rendering the app
  useEffect(() => {
    async function loadResourcesAndDataAsync() {
      try {
        SplashScreen.preventAutoHideAsync();

        // Load images
        // based on https://docs.expo.dev/versions/latest/sdk/app-loading/
        // and https://stackoverflow.com/a/42710306/594211
        const uris = Object.entries(images)
          .filter(([k, _]) => imageNamesToPreload.includes(k))
          .map(([_, source]) => source.uri);
        const prefetchTasks = uris.map(uri => Image.prefetch(uri));
        await Promise.all(prefetchTasks);

        // Load fonts
        await Font.loadAsync({
          // ...FontAwesome.font,
          ...Ionicons.font,
          // 'space-mono': require('../assets/fonts/SpaceMono-Regular.ttf'),
        });
      } catch (e) {
        // We might want to provide this error information to an error reporting service
        console.warn(e);
      } finally {
        setLoadingComplete(true);
        SplashScreen.hideAsync();
      }
    }

    loadResourcesAndDataAsync();
  }, []);

  return isLoadingComplete;
}
