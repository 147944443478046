import * as Analytics from 'expo-firebase-analytics';
import i18n from 'i18n-js';
import { useState } from 'react';
import { Image, StyleSheet, Text, View } from 'react-native';

import images from '../assets/images';
import Colors from '../constants/Colors';
import { useAuth } from '../contexts/AuthContext';
import { useData } from '../contexts/DataContext';
import useColorScheme from '../hooks/useColorScheme';
import { MyPressable } from './MyComponents';
import { themedStyles } from './Themed';


export default function ModuleUnlockButton(props: { moduleId: string, isSmall?: boolean, online?: boolean }) {
    // Call hooks
    const { authState } = useAuth();
    const colorScheme = useColorScheme();
    const { performUnlockDoors } = useData();
    const [isBusy, setBusy] = useState(false);

    const disabled = !props.online || isBusy;

    const iconSource = disabled
        ? (colorScheme === 'dark' ? images.unlockdoors_disabled_dark_sm : images.unlockdoors_disabled_sm)
        : (props.isSmall ? images.unlockdoors_normal_sm : images.unlockdoors_normal);

    async function onPress() {
        setBusy(true);
        if (authState?.userToken) {
            await performUnlockDoors(authState.userToken, props.moduleId);
        }
        setBusy(false);

        Analytics.logEvent('unlock_doors', {
            context: 'Module',
            moduleId: props.moduleId,
            nickname: authState.userObject.nickname,
        });
    }

    return <MyPressable style={({ pressed }) => [
        themedStyles.actionPressable,
        props.isSmall && styles.containerSmall,
        { backgroundColor: disabled ? Colors[colorScheme].actionDisabled : Colors[colorScheme].action },
        pressed && { backgroundColor: Colors[colorScheme].actionHighlighted }
    ]}
        disabled={disabled}
        onPress={async () => await onPress()}>
        {({ pressed }) =>
            isBusy ?
                <View style={styles.mainRow}>
                    <Text style={[
                        props.isSmall ? styles.buttonTitleSmall : styles.buttonTitle,
                        { color: disabled ? Colors[colorScheme].actionTitleDisabled : (pressed ? Colors[colorScheme].actionTitleHighlighted : Colors[colorScheme].actionTitle) }
                    ]}>{i18n.t("MODULEUNLOCKBUTTON_UNLOCKING_TITLE")}</Text>
                </View>
                :
                <View style={styles.mainRow}>
                    <Image source={iconSource} style={[
                        // disabled && { tintColor: colors.actionTitleDisabled },  // doesn't work reliably in iOS Safari
                        props.isSmall ? styles.buttonIconSmall : styles.buttonIcon
                    ]} />
                    <Text style={[
                        props.isSmall ? styles.buttonTitleSmall : styles.buttonTitle,
                        { color: disabled ? Colors[colorScheme].actionTitleDisabled : (pressed ? Colors[colorScheme].actionTitleHighlighted : Colors[colorScheme].actionTitle) }
                    ]}>{i18n.t("MODULEUNLOCKBUTTON_TITLE")}</Text>
                </View>
        }
    </MyPressable>;
}


const styles = StyleSheet.create({
    containerSmall: {
        width: 82,
        height: 27,
    },
    mainRow: {
        flexDirection: 'row',
        alignItems: 'center',   // vertically center
    },
    buttonIcon: {
        width: 18,
        height: 24
    },
    buttonIconSmall: {
        width: 12,
        height: 16
    },
    buttonTitle: {
        fontSize: 16,
        fontWeight: '500',
        marginLeft: 6,
    },
    buttonTitleSmall: {
        fontSize: 12,
        fontWeight: '500',
        marginLeft: 4,
    }
});
