import { StyleSheet } from 'react-native';

import TaskCheckbox from '../components/TaskCheckbox';
import { ThemedText } from '../components/Themed';
import { Task } from '../types';


export default function TaskListItem(props: { task: Task, text: string }) {
    return <TaskCheckbox {...props}>
        <ThemedText style={styles.taskListItemText}>{props.text}</ThemedText>
    </TaskCheckbox>;
}


const styles = StyleSheet.create({
    taskListItemText: {
        fontSize: 16,
        fontWeight: '400',
    }
});
