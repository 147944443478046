import { Dimensions, StyleSheet } from 'react-native';

const width = Dimensions.get('window').width;
const height = Dimensions.get('window').height;

export default {
  window: {
    width,
    height,
  },
  isSmallDevice: width < 375,
};


export const styles = StyleSheet.create({
  rootContainer: {
    flex: 1,

    // borderWidth: 1,
    // borderColor: 'red'
  },
  rootContentContainer: {
    alignItems: 'center',    // horizontal

    // borderWidth: 1,
    // borderColor: 'red'
  },
  // responsive, single column, effectively 320 px wide with 8 px padding
  mobileContainer: {
    width: 320,

    // borderWidth: 1,
    // borderColor: 'blue'
  },
  cardListView: {
    paddingBottom: 12,

    // borderWidth: 1,
    // borderColor: 'red'
  },
  cardView: {
    marginTop: 12,

    // borderWidth: 1,
    // borderColor: 'red'
  },
  paddedContent: {
    padding: 8,

    // borderWidth: 1,
    // borderColor: 'green'
  }
});
