
import Error from '../components/Error';
import Loading from '../components/Loading';
import ModuleListCard from "../components/ModuleListCard";
import { MyFlatList } from '../components/MyComponents';
import { styles as sharedLayoutStyles } from "../constants/Layout";
import { useData } from '../contexts/DataContext';
import { ModulesListScreenProps } from '../types';


export default function ModulesListScreen(props: ModulesListScreenProps) {
    const { modules, isLoading } = useData();

    if (modules) {
        modules.sort((a, b) => a.fullName.localeCompare(b.fullName))

        return <MyFlatList style={sharedLayoutStyles.rootContainer}
            contentContainerStyle={[sharedLayoutStyles.rootContentContainer, sharedLayoutStyles.cardListView]}
            contentInsetAdjustmentBehavior="automatic"
            data={modules}
            renderItem={({ item }) => <ModuleListCard module={item} />}
            keyExtractor={item => item.moduleId}
        />;
    }
    else {
        return isLoading ? <Loading /> : <Error />;
    }
}
