import Error from '../components/Error';
import Loading from '../components/Loading';
import { MyFlatList } from '../components/MyComponents';
import TasksModuleListItem from "../components/TasksModuleListItem";
import { styles as sharedLayoutStyles } from "../constants/Layout";
import { useData } from '../contexts/DataContext';
import { Task, TasksModuleListScreenProps } from "../types"


/*
    The list of modules for a single task type.
    (i.e. 1 task type, n modules)
*/
export default function TasksModuleListScreen(props: TasksModuleListScreenProps) {
    const { isLoading, tasks } = useData();

    if (tasks) {
        // Map modules to tasks
        const modulesWithTasksSet = new Set();
        const moduleIdsToTasksMap: Record<string, Task[]> = {};
        for (const t of tasks.filter(t => t.taskType === props.route.params.taskType)) {
            let array = moduleIdsToTasksMap[t.module.moduleId];
            if (!array) {
                array = []
                moduleIdsToTasksMap[t.module.moduleId] = array;
            }
            array.push(t);
            modulesWithTasksSet.add(t.module);
        }
        // console.debug(moduleIdsToTasksMap);
        const modulesWithTasks = Array.from(modulesWithTasksSet);

        return <MyFlatList style={sharedLayoutStyles.rootContainer}
            contentContainerStyle={[
                sharedLayoutStyles.rootContentContainer,
                sharedLayoutStyles.cardListView
            ]}
            data={modulesWithTasks}
            renderItem={({ item }) => <TasksModuleListItem taskType={props.route.params.taskType} module={item} moduleTasks={moduleIdsToTasksMap[item.moduleId]} />}
            keyExtractor={item => item.moduleId}
        />;
    }
    else {
        return isLoading ? <Loading /> : <Error />;
    }
}
