import { Ionicons } from '@expo/vector-icons';
import { useNavigation, useTheme } from '@react-navigation/native';
import i18n from 'i18n-js';
import { Image, StyleSheet, View } from 'react-native';

import images from '../assets/images/index';
import { styles as sharedLayoutStyles } from "../constants/Layout";
import useColorScheme from '../hooks/useColorScheme';
import { TaskType, TasksModuleListScreenProps } from '../types';
import { MyPressable } from './MyComponents';
import TasksCountBadge from './TasksCountBadge';
import { ThemedText } from './Themed';


export default function TasksTypeCard(props: { taskType: TaskType, taskCount: number, index: number }) {
    const colorScheme = useColorScheme();
    const { colors } = useTheme();
    // https://reactnavigation.org/docs/typescript/#annotating-usenavigation
    const navigation = useNavigation<TasksModuleListScreenProps['navigation']>();

    const TASK_DICT =
    {
        harvest: {
            titleKey: 'TASKTYPE_HARVEST',
            image: images.task_harvest,
            imageDark: images.task_harvest_white
        },
        remove_film: {
            titleKey: 'TASKTYPE_REMOVE_FILM',
            image: images.task_removefilm,
            imageDark: images.task_removefilm_white
        },
        refill_tank: {
            titleKey: 'TASKTYPE_REFILL_TANK',
            image: images.task_refilltank,
            imageDark: images.task_refilltank_white
        },
        clean_position: {
            titleKey: 'TASKTYPE_CLEAN_POSITION',
            image: images.task_clean_position,
            imageDark: images.task_clean_position_white
        },
        contact_us: {
            titleKey: 'TASKTYPE_CONTACT_US',
            image: images.task_contact_us,
            imageDark: images.task_contact_us_white
        },
    };

    return <View style={sharedLayoutStyles.mobileContainer}>
        {props.index === 0 &&
            <View style={styles.startHereRow}>
                <ThemedText style={[
                    styles.startHereText,
                    { backgroundColor: colors.border }
                ]}>{i18n.t('TASKSOVERVIEWSCREEN_STARTHERE_LABEL')}</ThemedText>
            </View>
        }
        <MyPressable
            style={({ pressed }) => [
                sharedLayoutStyles.cardView,
                styles.cardPressable,
                props.index === 0 && styles.cardPressableFirst,
                { backgroundColor: pressed ? colors.border : colors.card },
            ]}
            onPress={() => {
                navigation.navigate('TasksModuleList', { taskType: props.taskType })
            }}
        >
            <View style={styles.mainView}>
                <Image source={colorScheme === 'dark' ? TASK_DICT[props.taskType].imageDark : TASK_DICT[props.taskType].image} style={styles.imageColumn} />
                <View style={styles.titleChevronColumn}>
                    <View style={styles.titleChevronRow}>
                        <ThemedText style={styles.titleText}>{i18n.t(TASK_DICT[props.taskType].titleKey)}</ThemedText>
                        <TasksCountBadge count={props.taskCount} />
                        <Ionicons name="chevron-forward-outline" size={24} color={colors.text} />
                    </View>
                </View>
            </View>
        </MyPressable>
    </View >
}


const styles = StyleSheet.create({
    startHereRow: {
        flexDirection: 'row',

        zIndex: 1,
        backgroundColor: 'transparent',

        // borderWidth: 1,
        // borderColor: 'red'
    },
    startHereText: {
        marginLeft: 16,
        marginTop: 16,

        paddingVertical: 2,
        paddingHorizontal: 16,
        borderRadius: 2,

        textAlign: 'center',
        fontSize: 14,
        fontWeight: '500',

        // borderWidth: 1,
        // borderColor: 'blue'
    },
    cardPressable: {
        height: 80,
        paddingHorizontal: 8,
        justifyContent: 'center', // vertical

        borderRadius: 4,

        shadowOffset: {
            width: 0,       // X
            height: 2,      // Y
        },
        shadowRadius: 8,    // B
        shadowColor: '#767676',
        shadowOpacity: 0.1,
    },
    cardPressableFirst: {
        marginTop: -6,
    },
    mainView: {
        flexDirection: 'row',
        alignItems: 'center', // vertically

        // borderWidth: 1,
        // borderColor: 'red'
    },
    imageColumn: {
        width: 60,
        height: 60,
        marginHorizontal: 8,

        // borderWidth: 1,
        // borderColor: 'blue'
    },
    titleChevronColumn: {
        flex: 1,

        marginLeft: 12,

        // borderWidth: 1,
        // borderColor: 'blue'
    },
    titleChevronRow: {
        flexDirection: 'row',

        alignItems: 'center',
        justifyContent: 'space-between',
    },
    titleText: {
        flex: 1,

        fontSize: 20,
        fontWeight: '500',
    },
});
